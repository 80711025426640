import { Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import { SocialHistoryAccordion } from "./SocialHistoryAccordion";

import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import dayjs from "dayjs";

export function SocialHistory() {
  const { summaryData } = useMedicalCareContext();
  const { questionnaireDefualt } = useQuestionnaireContext();

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  // Array com todos os códigos LOINC que queremos filtrar
  const socialHistoryCodes = [
    "72166-2", // Tabagismo
    "74013-4", // Consumo diário de álcool
  ];

  // Array com os textos correspondentes
  const socialHistoryTexts = ["Tabagismo", "Consumo diário de álcool"];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry?.filter((e: any) => {
      const verifyDate = questionnaireDefualt
        ? dayjs(questionnaireDefualt?.resource?.authored).isAfter(
            e?.resource?.effectiveDateTime
          )
        : true;

      // Verifica se o código está na lista de códigos de histórico social
      const isSocialHistoryCode =
        e?.resource?.code?.coding?.[0]?.code &&
        socialHistoryCodes.includes(e?.resource?.code?.coding?.[0]?.code);

      // Verifica se o texto corresponde a algum histórico social
      const isSocialHistoryText = socialHistoryTexts.includes(
        e?.resource?.code?.text
      );

      return (isSocialHistoryCode || isSocialHistoryText) && verifyDate;
    }),
    encounterSummary
  );

  return (
    <GenericTimeline
      data={filterEncounter
        ?.filter((enc: any) =>
          questionnaireDefualt
            ? dayjs(enc?.answerDate).isAfter(
                questionnaireDefualt?.resource?.authored
              )
            : true
        )
        ?.sort((a: any, b: any) => {
          const dateA = dayjs(a?.answerDate);
          const dateB = dayjs(b?.answerDate);

          return dateB.diff(dateA);
        })}
      component={(data: any, index: number) => (
        <SocialHistoryAccordion data={data} index={index} />
      )}
    />
  );
}
