import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IObservationData } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

import { ObservationType } from "../../../utils/types";
import { auxiliarObservations } from "../AuxiliarObservation";

export async function PostObservation(
  objData: IObservationData,
  type: ObservationType
) {
  let url = `${endpoints.EHRPOSTER}/Observation`;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const data = auxiliarObservations.generateBodyObservation(objData, type);

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostObservation: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostObservation ", error);
      toast.error(`PostObservation Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
