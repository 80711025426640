import { WhatsApp } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CircularLoading,
  NoDataWarning,
} from "../../../../../components/common";
import { FormTextInput } from "../../../../../components/form/FormTextInput";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { oids } from "../../../../../configs/Settings";
import { useLocationContext } from "../../../../../contexts/LocationContext";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { useViewContext } from "../../../../../contexts/ViewContext";
import { PatientBasedAppointment } from "../../../../../services/fhir";
import { useFormatter } from "../../../../../utils/useFormatter";
import { useInternationalVerify } from "../../../../../utils/useInternationalVerify";
import { PatientSearchActions } from "../../../../patientsearch/components/PatientSearchActions";

interface FilterForm {
  nameFilter: string;
  cpfFilter: string;
}

export function LastAppointmentsTable() {
  const { user } = useSessionContext();
  const { viewType } = useViewContext();
  const { location } = useLocationContext();

  const [lastPatients, setLastPatients] = useState<fhir5.BundleEntry<fhir5.Patient>[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const { control, watch } = useForm<FilterForm>({
    defaultValues: {
      nameFilter: "",
      cpfFilter: ""
    }
  });

  const nameFilter = watch("nameFilter") || "";
  const cpfFilter = watch("cpfFilter") || "";

  const locationId = location
    ?.map(
      (element: fhir5.BundleEntry<fhir5.Location>) =>
        `Location/${element.resource?.id}`
    )
    ?.join(",");

  const columns = [
    { column: "Nome", rowName: "name" },
    { column: "CPF", rowName: "identifier" },
    { column: "Data de nascimento", rowName: "birthDate" },
    { column: "Sexo de nascimento", rowName: "gender" },
    { column: "Telefone", rowName: "contact" },
  ];

  const rows = lastPatients?.map((el: any) => {
    const identifier = useFormatter.formatCPF(el.resource?.id.split("-")[1]);
    const name =
      useFormatter.formatNameDisplay(el.resource?.name?.[0]?.text) || "-";
    const birthDate =
      el.resource?.birthDate &&
      `${useFormatter.formatDate(
        el.resource?.birthDate
      )} - ${useFormatter.formatAge(el.resource?.birthDate)} anos`;
    const gender = useFormatter.formatGender(el.resource?.gender) || "-";

    const contact = el?.resource?.telecom;

    const phone = contact?.find((e: any) => e.system === "phone")?.value;

    return {
      identifier,
      name,
      birthDate,
      ...(phone && {
        contact: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography mt={0.5}>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                      phone
                    )}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
      gender,
    };
  });

  const filteredRows = rows?.filter((row) => {
    const nameMatch = row.name.toLowerCase().includes(nameFilter.toLowerCase());
    const cpfMatch = row.identifier.toLowerCase().includes(cpfFilter.toLowerCase());
    return nameMatch && cpfMatch;
  });

  useEffect(() => {
    async function fetchPatients() {
      setLoading(true);
      try {
        // Definindo profissional atual
        const practitionerActor = 
          viewType === "user" ? `Practitioner/${oids.cpf}-${user.username}` : undefined;

        // Obtendo pacientes com base nos agendamentos
        const patientResponse = await PatientBasedAppointment({
          locationActors: locationId,
          practitionerActor,
          // status: ["fulfilled", "booked"],
        });

        setLastPatients(patientResponse?.entry);
      } catch (err) {
        console.log("fetchPatientsError", err);
      } finally {
        setLoading(false);
      }
    }
    if (locationId) {
      fetchPatients();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, locationId]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <FormTextInput
          name="nameFilter"
          control={control}
          label="Filtrar por nome"
          placeholder="Digite o nome do paciente"
        />
        <FormTextInput
          name="cpfFilter"
          control={control}
          label="Filtrar por CPF"
          placeholder="Digite o CPF do paciente"
          mask={useFormatter?.formatCPF}
        />
      </Stack>
      
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {rows?.length ? (
            <GenericTable
              tableColumns={columns}
              tableRows={filteredRows?.reverse()}
              actions={(rowData: any) => <PatientSearchActions row={rowData} />}
            />
          ) : (
            <NoDataWarning message="Nenhum atendimento recente foi encontrado" />
          )}
        </>
      )}
    </Stack>
  );
}
