import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IPatchOperation } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import { auxiliarData } from "../AuxiliarData";

interface IProps {
  carePlanId: string;
  status?: "entered-in-error" | "final";
}

export async function PatchCarePlanStatus({ carePlanId, status }: IProps) {
  const url = `${endpoints.EHRPOSTER}/CarePlan/${carePlanId}`;

  let operations: IPatchOperation[] = [];
  if (status)
    operations.push({
      op: "replace",
      path: "/status",
      value: status,
    });
  const data = auxiliarData.Patch({
    operations,
  });
  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/json-patch+json",
  };

  const response = await useAxios
    .patch(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatchEncounter: ${response.status}`);
      }
      return { data: response.data, status: response.status };
    })
    .catch((error) => {
      console.error("PatchStatusCarePlan ", error);
      // toast.error(`PatchStatusCarePlan Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
