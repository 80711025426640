import { Grid } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { InputText } from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";

export function VitalSignsFields({ gender }: any) {
  const { control } = useMedicalCareContext();

  return (
    <Grid container spacing={3} marginTop={0.8}>
      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Pressão arterial Sistólica"}
          name={"Anthropometric.pressure.sistolic"}
          control={control}
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Pressão arterial Diastólica"}
          name={"Anthropometric.pressure.diastolic"}
          control={control}
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Temperatura (°C)"}
          name={"Anthropometric.temperature"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Frequência respiratória (rpm)"}
          name={"Anthropometric.respiratoryRate"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Frequência cardíaca (bpm)"}
          name={"Anthropometric.cardiacRate"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Glicemia capilar (mg/dL)"}
          name={"Anthropometric.glicemiaCapilar"}
          control={control}
          type="number"
        />
      </Grid>

      <Grid
        item
        xs={6}
        padding="24px 24px 24px 0px"
        sx={{
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <InputText
          label={"Dor (0-10)"}
          name={"Anthropometric.pain"}
          control={control}
          type="number"
        />
      </Grid>
    </Grid>
  );
}
