import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

const filterDict: { [key: string]: string } = {
  "1": "int-product",
  "2": "int-substance",
};

export async function GetMedicationINT(
  filter?: any,
  filterValue?: string,
  code?: string
) {
  let url = `${endpoints.TERMINOLOGY}/Medication`;
  if (filter && filterValue)
    url = `${url}?${[filterDict[filter]]}=${filterValue}&_count=250`;
  if (code) url = `${url}?code=${code}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetMedication: ${response.status}`);
      }

      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetMedication ", error);
      // toast.error(`GetMedication Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
