import { Publish } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  FormAutoComplete,
  FormCheckBox,
  FormTextInput,
} from "../../../components/form";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { GetMedication } from "../../../services/terminology";
import { CustomMedicationOption } from "../../medicaldocumentation/components/prescription/CustomMedicationOption";
import { GetMedicationINT } from "@/services/terminology/GetMedicationINT";

const unit = [
  {
    value: "GRAN",
    label: "Grânulos",
    inputType: "number",
  },
  {
    value: "COM",
    label: "Comprimido",
    inputType: "number",
  },
  {
    value: "CAP",
    label: "Cápsula",
    inputType: "number",
  },
  {
    value: "SOL OR",
    label: "Solução Oral",
  },
  {
    value: "SOL INJ",
    label: "Solução Injetável",
  },
  {
    value: "SOL",
    label: "Solução",
  },

  {
    value: "SUSP",
    label: "Suspensão",
  },
  {
    value: "POM",
    label: "Pomada",
  },
  {
    value: "GEL",
    label: "Gel",
  },
  {
    value: "XAR",
    label: "Xarope",
  },
  {
    value: "INJ",
    label: "Injetável",
  },
  {
    value: "COL",
    label: "Colírio",
  },
  {
    value: "AER",
    label: "Aerossol",
  },
  {
    value: "SUP",
    label: "Supositório",
    inputType: "number",
  },
  {
    value: "AMP",
    label: "Ampola",
    inputType: "number",
  },
  {
    value: "CTG",
    label: "Cartucho",
    inputType: "number",
  },
  {
    value: "FLD",
    label: "Fluido",
  },
  {
    value: "CJ  ",
    label: "Conjunto",
    inputType: "number",
  },
  {
    value: "ENV",
    label: "Envelope ou Sachê",
  },
  {
    value: "BISN",
    label: "Bisnaga",
    inputType: "number",
  },
  {
    value: "DISP",
    label: "Dispositivo",
  },
  {
    value: "BALA",
    label: "Bala",
    inputType: "number",
  },
  {
    value: "LAQ",
    label: "Laca",
  },
];

const frequency = [
  { value: 3, label: "3 h" },
  { value: 6, label: "6 h" },
  { value: 8, label: "8 h" },
  { value: 12, label: "12 h" },
  { value: 24, label: "24 h" },
];

const time = [
  { value: "", label: "tempo indeterminado" },
  { value: 1, label: "1 dia" },
  { value: 2, label: "2 dias" },
  { value: 3, label: "3 dias" },
  { value: 5, label: "5 dias" },
  { value: 7, label: "7 dias" },
  { value: 15, label: "15 dias" },
];

export function MedicationFields({ control, name, setValue, render }: any) {
  const { medicationOptions, setMedication } = useInterventionsContext();
  const [type, setType] = useState(1);

  function verityInputType() {
    const option = unit.find((el: any) => el.value === selectedForm?.value);
    return option?.inputType || "text";
  }

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  let selectedMedication = useWatch({ name: "medication", control });
  let selectedForm = useWatch({ name: "form", control });

  const specialControl = useWatch({ name: "specialControl", control });

  const [customMedication, setCustomMedication] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchMedication() {
      const medicationResponse =
        name !== "fitoterápico"
          ? await GetMedication(type?.toString(), selectedMedication?.name)
          : await GetMedicationINT(type?.toString(), selectedMedication?.name);
      setMedication(
        medicationResponse?.data?.entry?.map((el: any) => el.resource)
      );
      setLoading(false);
    }

    if (selectedMedication?.code) setCustomMedication(false);

    const presentationForm = unit?.find((el: any) =>
      selectedMedication?.label?.includes(el.value)
    );
    if (presentationForm && !customMedication) {
      setValue("form", presentationForm);
    }

    const debounce = setTimeout(() => {
      if (selectedMedication?.name?.length) {
        setLoading(true);
        fetchMedication();
      }
    }, 1500);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedication, setValue]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {name === "medicamento" && (
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Stack>
              <FormLabel component="legend"> Buscar por: </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                aria-label="position"
                sx={{ width: "100%" }}
                defaultValue={1}
                // value={field?.value ?? ''}
              >
                <FormControlLabel
                  key={1}
                  value={1}
                  control={<Radio />}
                  onChange={() => setType(1)}
                  label="Nome comercial"
                />
                <FormControlLabel
                  key={2}
                  value={2}
                  control={<Radio />}
                  onChange={() => setType(2)}
                  label="Princípio ativo"
                />
                <FormControlLabel
                  key={3}
                  value={3}
                  control={<Radio />}
                  onChange={() => setType(3)}
                  label="Nome genérico"
                />
              </RadioGroup>
            </Stack>

            <FormCheckBox
              name="specialControl"
              control={control}
              label="Controle especial"
              checked={specialControl}
            />
          </Stack>
        </Grid>
      )}

      {name === "fitoterápico" && (
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Stack>
              <FormLabel component="legend"> Buscar por: </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                aria-label="position"
                sx={{ width: "100%" }}
                defaultValue={1}
                // value={field?.value ?? ''}
              >
                <FormControlLabel
                  key={1}
                  value={1}
                  control={<Radio />}
                  onChange={() => setType(1)}
                  label="Nome comercial"
                />
                <FormControlLabel
                  key={2}
                  value={2}
                  control={<Radio />}
                  onChange={() => setType(2)}
                  label="Princípio ativo"
                />
              </RadioGroup>
            </Stack>
          </Stack>
        </Grid>
      )}

      <Grid item xs={12}>
        {render && (
          <FormAutoComplete
            required
            loading={loading}
            name="medication"
            freeSolo={customMedication}
            control={control}
            onInputChange={(e: any) => {
              if (e?.target?.value?.length)
                setValue("medication", {
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
            }}
            label={`Selecione o ${name}`}
            renderOption={(option: any) => (
              <CustomMedicationOption option={option} />
            )}
            noOptionsText={
              <Button
                sx={{ width: "fit-content", textTransform: "none" }}
                onClick={() => setCustomMedication(true)}
                endIcon={<Publish />}
              >
                Adicionar novo medicamento
              </Button>
            }
            selectOptions={!loading ? medicationOptions : []}
            autoFocus
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {render && (
          <FormTextInput
            type={verityInputType()}
            name="quantity"
            control={control}
            label="Posologia"
            pattern={/^\d+(\.\d+)?\s*\w*(\s+.*)?$/}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {render && (
          <FormAutoComplete
            onInputChange={(e: any) => {
              if (e?.target?.value?.length)
                setValue("form", {
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
            }}
            freeSolo
            name="form"
            control={control}
            label="Forma"
            selectOptions={unit}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        {render && (
          <FormAutoComplete
            freeSolo
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                const debounce = setTimeout(() => {
                  if (extrairNumero(e?.target?.value) !== null)
                    setValue("frequency", {
                      name: `${extrairNumero(e?.target?.value)} h` || "",
                      label: `${extrairNumero(e?.target?.value)} h` || "",
                      value: extrairNumero(e?.target?.value) || "",
                    });
                  else {
                    setValue("frequency", {
                      name: "",
                      label: "",
                      value: "",
                    });
                  }
                }, 1000);
                return () => clearTimeout(debounce);
              }
            }}
            name="frequency"
            control={control}
            label="Intervalo (horas)"
            selectOptions={frequency}
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {render && (
          <FormAutoComplete
            freeSolo
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                const debounce = setTimeout(() => {
                  if (extrairNumero(e?.target?.value) !== null)
                    setValue("time", {
                      name: `${extrairNumero(e?.target?.value)} dias` || "",
                      label: `${extrairNumero(e?.target?.value)} dias` || "",
                      value: extrairNumero(e?.target?.value),
                    });
                  else {
                    setValue("time", {
                      name: "",
                      label: "",
                      value: "",
                    });
                  }
                }, 1000);
                return () => clearTimeout(debounce);
              }
            }}
            name="time"
            control={control}
            label="Tempo (dias)"
            selectOptions={time}
          />
        )}
      </Grid>
      {render && (
        <Grid item xs={12} height="auto">
          <FormTextInput
            multiline={true}
            minRows={3}
            label="Observação"
            name="note"
            control={control}
          />
        </Grid>
      )}
    </Grid>
  );
}
