import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useCustomizationContext } from "../../../../contexts/CustomizationContext";

import { ExamesHistoryView } from "./components/ExamesHistoryView";
import { ExamesInput } from "./components/ExamesInput";

export function Exames() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loadingResponse } = useQuestionnaireContext();
  const { itens } = useCustomizationContext();

  // Estado para controlar a aba selecionada
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      width="100%"
      sx={{
        minHeight: isMobile ? "auto" : "59vh",
        padding: 1,
      }}
    >
      {/* Componente de entrada de dados */}
      <ExamesInput
        items={itens}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        isMobile={isMobile}
      />

      {/* Componente de visualização do histórico */}
      {!loadingResponse && (
        <ExamesHistoryView
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          isMobile={isMobile}
        />
      )}
    </Stack>
  );
}
