import { Box, Tab, Tabs, styled } from "@mui/material";
import { SyntheticEvent, useMemo } from "react";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { useQuestionnaireContext } from "../../../contexts/QuestionnaireContext";
import { useFhirData } from "../../../utils/useFhirData";
import { Anamnese } from "./Anamnese/Anamnese";
import { Antropometricos } from "./Anthropometrics/Antropometricos";
import { Condutas } from "./Condutas/Condutas";
import { Diagnosticos } from "./Diagnosticos/Diagnosticos";
import { Exames } from "./Exames/Exames";
import { KeyPressShortCutTabs } from "./KeyPressShortcuts/KeyPressShortcut";
import { PlanosDeTratamento } from "./PlanosDeTratamento/PlanosDeTratamento";
import { Problemas } from "./Problemas/Problemas";
import { Resultado } from "./Resultados/Resultados";
import { SocialHabits } from "./SocialHabits/SocialHabits";
import { TratamentosDeclarado } from "./TratamentosDeclarado/TratamentosDeclarado";
import { TabPanel } from "./TabPanel/TabPanel";
import { scrollbarStyle } from "./scrollbarStyle";
import { VitalSigns } from "./VitalSigns/VitalSigns";
import { Immunizations } from "./Immunizations/Immunizations";
import { ObjectiveData } from "./ObjectiveData/ObjectiveData";

interface TabConfig {
  label: string;
  component: React.ReactNode;
}

// Função auxiliar para propriedades de acessibilidade
const getTabProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

const TabsContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "100%",
  overflow: "hidden",
});

// Container para as tabs verticais
const TabsWrapper = styled(Box)({
  minWidth: "200px",
  maxWidth: "250px",

  borderRight: "1px solid divider",
  overflow: "hidden",
  display: "flex",
});

// Estilização das tabs com scroll nativo do MUI
const ScrollableTabs = styled(Tabs)({
  width: "100%",
  maxHeight: "calc(48px * 10)", // Altura máxima para 10 tabs
  "& .MuiTabs-indicator": {
    right: 0,
  },
});

// Estilização individual das tabs
const StyledTab = styled(Tab)({
  textAlign: "left",

  "&.Mui-selected": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

// Container para o conteúdo da tab
const TabContentWrapper = styled(Box)({
  flex: 1,

  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

export function TabsAtendimento() {
  const { registerAreaTab, setRegisterAreaTab, isNursingService } =
    useMedicalCareContext();
  const { loadingQuestionnaire } = useQuestionnaireContext();

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setRegisterAreaTab(newValue);
  };

  // Configuração das tabs usando useMemo para evitar recriações desnecessárias
  const tabsConfig: TabConfig[] = useMemo(() => {
    // Configuração padrão para outros serviços
    return [
      { label: "Anamnese", component: <Anamnese /> },
      { label: "Exames físicos", component: <Exames /> },
      { label: "Dados Objetivos", component: <ObjectiveData /> },
      { label: "Tratamentos", component: <TratamentosDeclarado /> },
      { label: "Lista de problemas", component: <Problemas /> },
      { label: "Diagnósticos", component: <Diagnosticos /> },
      { label: "Condutas", component: <Condutas /> },
      { label: "Planos de Tratamento", component: <PlanosDeTratamento /> },
      { label: "Resultados de exames", component: <Resultado /> },
      { label: "Hábitos Sociais", component: <SocialHabits /> },
    ];
  }, []);

  if (loadingQuestionnaire) {
    return null;
  }

  return (
    <>
      <KeyPressShortCutTabs />
      <TabsContainer>
        <TabsWrapper>
          <ScrollableTabs
            orientation="vertical"
            variant="scrollable"
            value={registerAreaTab}
            onChange={handleChange}
            aria-label="Abas de atendimento médico"
            scrollButtons={false} // Desabilita botões de scroll
            allowScrollButtonsMobile // Permite scroll em dispositivos móveis
            sx={{
              borderRight: 1,
              borderColor: "divider",
            }}
          >
            {tabsConfig.map((tab, index) => (
              <Tab key={index} label={tab.label} {...getTabProps(index)} />
            ))}
          </ScrollableTabs>
        </TabsWrapper>

        <TabContentWrapper>
          <TabPanel index={registerAreaTab} value={registerAreaTab}>
            {tabsConfig[registerAreaTab]?.component}
          </TabPanel>
        </TabContentWrapper>
      </TabsContainer>
    </>
  );
}
