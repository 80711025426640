import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAuth } from "../../utils/useAuth";
import { useAxios } from "../../utils/useAxios";

interface IProps {
  filter?: string;
}
export async function GetCIAP2({ filter }: IProps) {
  let url = ` ${endpoints.TERMINOLOGY}/ValueSet/$expand?url=https://fluxmed.com.br/fhir/r4/ValueSet/CIAP2&count=150`;

  if (filter) url = `${url}&filter=${filter}`;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };
  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`GetValueSet CIAP2: ${response.status}`);
      }

      const sortResponse = response?.data?.expansion?.contains?.sort(
        (elemInitial: any, elemCompare: any) =>
          elemInitial?.code > elemCompare?.code ? 1 : -1
      );

      response.data.expansion.contains = sortResponse;

      return { status: HTTPResponseStatus.Success, data: response.data };
    })
    .catch((error) => {
      console.error("GetValueSet ", error);
      // toast.error(`GetValueSet Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
