import dayjs from "dayjs";
import * as uuid from "uuid";
import { endpoints, oids } from "../../configs/Settings";

import {
  IAppointmentData,
  IEmail,
  IEncounterData,
  IPatch,
  IPatientData,
  IPractitionerData,
  IPractitionerRoleData,
  IRelatedPersonData,
  ISlotData,
} from "../../utils/interfaces";
import { useFhirData } from "../../utils/useFhirData";
import { useFormatter } from "../../utils/useFormatter";

function Practitioner(objData: IPractitionerData) {
  return JSON.stringify({
    resourceType: "Practitioner",
    id: `${oids.cpf}-${objData.identifier}`,
    identifier: [
      {
        system: `urn:oid:${oids.cpf}`,
        value: objData.identifier,
      },
    ],
    birthDate: objData.birthDate,
    name: [{ text: objData.name }],
  });
}

function PersonAux(objData: any) {
  return JSON.stringify({
    resourceType: "Person",
    id: `${oids.cpf}-${objData.identifier}`,
    identifier: [
      {
        system: `urn:oid:${oids.cpf}`,
        value: objData.identifier,
      },
    ],
    active: objData?.active || true,
    name: [{ text: objData.name }],
    birthDate: objData.birthDate,
    extension: [
      {
        url: "https://fluxmed.cloud/termRefused",
        valueBoolean: objData.termRefused || false,
      },
    ],
  });
}

function PractitionerRole(objData: IPractitionerRoleData) {
  const organizationId = objData.organizationId.split("-")?.[1];
  const cpf = objData.practitionerId.split("-")[1];

  return JSON.stringify({
    resourceType: "PractitionerRole",
    id: `${organizationId}-${oids.cpf}-${cpf}`,
    practitioner: {
      reference: `Practitioner/${objData.practitionerId}`,
      display: objData.practitionerDisplay,
    },
    organization: {
      reference: `Organization/${objData.organizationId}`,
      display: objData.organizationDisplay,
    },
    code: objData?.invite
      ? [
          {
            coding: [
              {
                system: "invited",
                code: "RL_INVITED",
              },
            ],
          },
          {
            ...(objData?.role?.includes("practitioner") && {
              coding: [
                {
                  system: "practitioner",
                  code: "RL_PRACTITIONER",
                },
              ],
            }),
          },
          {
            ...(objData?.role?.includes("manager") && {
              coding: [
                {
                  system: "manager",
                  code: "RL_MANAGER",
                },
              ],
            }),
          },
          {
            ...(objData?.role?.includes("owner") && {
              coding: [
                {
                  system: "owner",
                  code: "RL_OWNER",
                },
              ],
            }),
          },
        ]
      : [
          {
            coding: [
              {
                system: "owner",
                code: "RL_OWNER",
              },
            ],
          },
          {
            coding: [
              {
                system: "manager",
                code: "RL_MANAGER",
              },
            ],
          },
        ],
  });
}

function PatchPractitionerRole(rolesSelected: any) {
  const owner = {
    coding: [
      {
        system: "owner",
        code: "RL_OWNER",
      },
    ],
  };
  const manager = {
    coding: [
      {
        system: "manager",
        code: "RL_MANAGER",
      },
    ],
  };
  const practitioner = {
    coding: [
      {
        system: "practitioner",
        code: "RL_PRACTITIONER",
      },
    ],
  };

  const updatedRoles = [owner, manager, practitioner].filter(
    (el: fhir5.CodeableConcept) =>
      rolesSelected.some((value: any) =>
        el?.coding?.[0]?.system?.includes(value)
      )
  );

  return updatedRoles;
}

function Location(objData: any, access: any, uuid?: string) {
  return JSON.stringify({
    id: uuid,
    resourceType: "Location",
    status: "active",
    name: objData.name,
    alias: objData.name,
    address: {
      city: objData.city,
      state: objData.state,
      postalCode: objData.cep,
      country: "Brasil",
      line: [
        objData.city && `${objData.city}`,
        objData.state && `${objData.state}`,
        objData.cep && `${objData.cep}`,
        objData.number && ` ${objData.number}`,
        objData.street && ` ${objData.street}`,
        objData.complement && ` ${objData.complement}`,
      ],
    },
    ...(objData?.phoneClinic && {
      contact: [
        {
          telecom: [
            {
              system: "phone",
              value: objData?.phoneClinic,
            },
          ],
        },
      ],
    }),
    managingOrganization: {
      reference: `Organization/${oids.cnpj}-${access}`,
    },
    extension: [
      {
        url: "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service",
        valueBoolean: objData.serviceOnline,
      },
    ],
  });
}

function Slot(objData: ISlotData, uuid?: string) {
  return JSON.stringify({
    id: uuid,
    resourceType: "Slot",
    appointmentType: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/v2-0276",
          version: "2.9",
          code: objData?.type,
        },
      ],
      text: objData?.type,
    },
    schedule: {
      reference: `Schedule/${objData?.scheduleId}`,
    },
    status: "free",
    start: objData.start,
    end: objData.end,
    overbooked: false,
  });
}

function HealthcareService(objData: any, access: any, uuid?: string) {
  const auxTimeAvailable = objData?.timeAvailable.map((timeAvailable: any) => {
    const availableInterval = objData.daysOfWeek?.map((day: any) => {
      return {
        daysOfWeek: timeAvailable[day] && useFormatter.translateWeekDays(day),
        availableStartTime: useFormatter.formatTime(
          timeAvailable[day]?.timeStart
        ),
        availableEndTime: useFormatter.formatTime(timeAvailable[day]?.timeEnd),
      };
    });

    const cleanInterval = availableInterval.filter(
      (interval: any) => interval?.daysOfWeek !== undefined
    );

    const groupInterval: { [key: string]: boolean } = {};

    const availableObjects: any[] = [];

    cleanInterval.forEach(
      (obj: {
        availableStartTime: any;
        availableEndTime: any;
        daysOfWeek: any;
      }) => {
        const timeValues = `${obj.availableStartTime}-${obj.availableEndTime}`;

        if (!groupInterval[timeValues]) {
          groupInterval[timeValues] = true;
          availableObjects.push({
            availableStartTime: obj.availableStartTime,
            availableEndTime: obj.availableEndTime,
            daysOfWeek: [obj.daysOfWeek],
          });
        } else {
          const sameInterval = availableObjects.find(
            (item: any) =>
              item.availableStartTime === obj.availableStartTime &&
              item.availableEndTime === obj.availableEndTime
          );
          sameInterval.daysOfWeek.push(obj.daysOfWeek);
        }
      }
    );
    return availableObjects;
  });

  let mergeTime = auxTimeAvailable[0].concat(auxTimeAvailable[1]);

  return JSON.stringify({
    id: uuid,
    resourceType: "HealthcareService",
    providedBy: {
      reference: `Organization/${oids.cnpj}-${access}`,
    },
    name: objData.healthService,
    appointmentRequired: true,
    availability: { availableTime: mergeTime },
    //R4 Version availableTime: mergeTime,
  });
}
function Schedule(objData: any, reference: any, uuid?: string) {
  const actors = [
    {
      reference: `Location/${reference.locationReference.id}`,
      display: reference.locationReference.name,
    },
    {
      reference: `HealthcareService/${reference.healthcareReference.id}`,
      display: reference.healthcareReference.name,
    },
    {
      reference: reference.practitionerReference?.value,
      display: reference.practitionerReference?.label,
    },
  ];

  const auxPersonTime =
    parseInt(dayjs(objData.personDurationTime).format("HH:mm").split(":")[0]) *
      60 +
    parseInt(dayjs(objData.personDurationTime).format("HH:mm").split(":")[1]);

  const formatDurationTime =
    objData.durationTimeDefault === "personDuration"
      ? auxPersonTime
      : objData.durationTimeDefault === "01:00"
      ? 60
      : parseInt(objData.durationTimeDefault.split(":")[1]);

  return JSON.stringify({
    id: uuid,
    resourceType: "Schedule",
    name: objData.scheduleName,
    active: true,
    actor: actors,
    planningHorizon: {
      start: objData.dataStart
        .toISOString()
        .split("T")[0]
        .concat("T00:00:00.000"),
      end: objData.dataEnd.toISOString().split("T")[0].concat("T23:59:59.999"),
    },
    extension: [
      {
        url: "https://fluxmed.com.br/fhir/StructureDefinition/schedule-slot-duration",
        valueDuration: {
          value: `${formatDurationTime}`,
          unit: "minutes",
          system: "http://unitsofmeasure.org",
          code: "min",
        },
      },
      {
        url: "https://fluxmed.com.br/fhir/StructureDefinition/schedule-slot-anticipation-period",
        valueDuration: {
          value: `${objData.advanceWarning || 15}`,
          unit: "days",
          system: "http://unitsofmeasure.org",
          code: "d",
        },
      },
      // R4 Version {
      //   url: "https://fluxmed.com.br/fhir/StructureDefinition/scheduleName",
      //   valueString: objData.scheduleName,
      // },
    ],
  });
}

function Patient(objData: IPatientData) {
  return JSON.stringify({
    resourceType: "Patient",
    identifier: [
      {
        use: "official",
        type: {
          coding: [
            {
              system: "http://terminology.hl7.org/CodeSystem/v2-0203",
              code: "TAX",
            },
          ],
        },
        system: `urn:oid:${oids.cpf}`,
        value: objData.identifier,
      },
    ],
    active: true,
    name: [
      {
        use: "official",
        text: useFormatter.fixName(objData.name.toUpperCase()),
      },
      {
        use: "usual",
      },
    ],
    telecom: [
      {
        system: "phone",
        value: objData?.phoneNumber,
      },
    ],
    gender: objData.gender,
    birthDate: objData.birthDate,
    deceasedBoolean: false,
    multipleBirthBoolean: false,
    ...(objData.link && { link: objData.link }),
  });
}

function RelatedPerson(
  objData: IRelatedPersonData,
  patientActor: { reference: string; display: string },
  status?: true | false
) {
  return JSON.stringify({
    resourceType: "RelatedPerson",
    active: status || false,
    patient: {
      display: patientActor?.display,
      reference: patientActor?.reference,
    },
    relationship: [
      {
        text: objData?.relationship,
      },
    ],
    name: [
      {
        use: "official",
        text: objData?.ownerName,
      },
    ],
    identifier: [
      {
        system: oids.cpf,
        value: objData?.cpfResponsible?.replace(/[.-]/g, ""),
      },
    ],
    telecom: [
      {
        system: "phone",
        value: `${objData?.phoneNumber?.replace(/[^0-9]/g, "")}`,
      },
    ],
    period: {
      start: dayjs().toISOString(),
    },
    gender: objData?.gender,
  });
}

function Appointment(objData: IAppointmentData) {
  const patientId = objData?.patientActor?.reference?.split("-")?.[1];

  return JSON.stringify({
    resourceType: "Appointment",
    status: "booked",
    priority: 0,
    slot: [
      {
        reference: `Slot/${objData.slotInfo.id}`,
      },
    ],
    start: objData.slotInfo.start,
    end: objData.slotInfo.end,
    appointmentType: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/v2-0276",
          version: "2.9",
          code: objData.typeService,
        },
      ],
      text: objData.typeService,
    },
    ...(objData?.virtualService && {
      virtualService: {
        addressUrl: objData?.virtualService?.[0]?.addressUrl,
        sessionKey: objData?.virtualService?.[0]?.sessionKey,
      },
    }),
    participant: [
      {
        actor: {
          reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
          display: objData.patientActor.display,
        },
      },
      {
        actor: {
          reference: objData.locationActor.reference,
          display: objData.locationActor.display,
        },
      },
      {
        actor: {
          reference: objData.healthCareServiceActor.reference,
          display: objData.healthCareServiceActor.display,
        },
      },
      {
        actor: {
          reference: objData.professionalActor.reference,
          display: objData.professionalActor.display,
        },
      },
    ],
  });
}

function Encounter(objData: IEncounterData) {
  const patient = useFhirData.findParticipant(objData?.participant, "Patient");
  const patientId = patient?.reference?.split("-")?.[1];

  const healthcareService = useFhirData.findParticipant(
    objData?.participant,
    "HealthcareService"
  );
  const location = useFhirData.findParticipant(
    objData?.participant,
    "Location"
  );

  return JSON.stringify({
    resourceType: "Encounter",
    identifier: [
      {
        system: "urn:ietf:rfc:3986",
        value: `${uuid.v1()}`,
      },
    ],
    status: "in-progress",
    type: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/v2-0276",
            version: "2.9",
            code: objData?.type,
          },
        ],
        text: objData?.type,
      },
    ],
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    serviceType: {
      reference: healthcareService,
    },
    appointment: {
      reference: `Appointment/${objData.appointment}`,
    },
    participant: [
      {
        actor: {
          reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
          display: patient?.display,
        },
      },
      objData.participant?.find((el: any) =>
        el.actor.reference.includes("Practitioner")
      ),
    ],
    actualPeriod: {
      start: dayjs().toISOString(),
    },
    serviceProvider: {
      reference: `Organization/${objData.organization}`,
    },
    location: {
      reference: location,
    },
  });
}

function AllergyIntolerance(objData: any, encounter: any) {
  const patientId = encounter.subject;
  return JSON.stringify({
    resourceType: "AllergyIntolerance",
    category: [`${objData?.category?.value}`],
    criticality: objData?.criticality?.value,
    ...((objData.description || objData?.note) && {
      reaction: [
        {
          description: objData?.descripto,
          note: {
            text: objData?.note,
          },
        },
      ],
    }),
    clinicalStatus: {
      coding: [{ code: objData?.clinicalStatus?.value }],
    },
    verificationStatus: {
      coding: [{ code: objData?.verificationStatus?.value }],
    },
    code: {
      text: objData?.substance,
    },
    patient: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    participant: [
      {
        function: {
          coding: [{ code: "informant" }],
        },
        actor: {
          reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
        },
      },
      {
        function: {
          coding: [{ code: "enterer" }],
        },
        actor: {
          reference: `Practitioner/${oids.cpf}-${objData.practitionerId}`,
          display: objData?.practitionerName,
        },
      },
    ],
    encounter: {
      reference: `Encounter/${encounter.id}`,
    },
    recordedDate: dayjs().toISOString(),
  });
}

function ProtocolResponse({
  id,
  objData,
  references,
}: {
  objData: any;
  references?: any;
  id?: string;
}) {
  const medicationAwnser = objData?.medicamentos?.map((medicamento: any) => {
    const answers = [
      medicamento.name && {
        linkId: "8543714373691",
        answer: [{ valueString: medicamento.name }],
      },
      medicamento.type && {
        linkId: "2794386593077",
        answer: [{ valueString: medicamento.type }],
      },

      medicamento.inputValue?.code && {
        linkId: "2043756634085",
        answer: [
          { valueString: medicamento.inputValue?.code?.coding?.[0]?.display },
        ],
      },
      medicamento.inputValue?.value && {
        linkId: "5252423656882",
        answer: [{ valueString: medicamento.inputValue.value }],
      },
      medicamento.inputValue?.presentation && {
        linkId: "6273182343815",
        answer: [{ valueString: medicamento.inputValue.presentation }],
      },
      medicamento.tag && {
        linkId: "7570117070815",
        answer: [{ valueString: medicamento.tag }],
      },
      medicamento.note && {
        linkId: "5201559249537",
        answer: [{ valueString: medicamento.note }],
      },
      // Adicionamos as entradas de resposta restantes se os valores não forem nulos
      medicamento.formValue?.label
        ? {
            linkId: "4027816497232",
            answer: [{ valueString: medicamento.formValue.label }],
          }
        : null,
      medicamento.quantity
        ? {
            linkId: "4145930684259",
            answer: [
              {
                valueString: medicamento.quantity.label || medicamento.quantity,
              },
            ],
          }
        : null,
      medicamento.frequency?.value
        ? {
            linkId: "1265328124778",
            answer: [{ valueString: medicamento.frequency?.value }],
          }
        : null,
      medicamento?.time?.value
        ? {
            linkId: "6391319168670",
            answer: [{ valueString: medicamento.time?.value }],
          }
        : null,
    ].filter(Boolean); // Removemos valores nulos do array
    return {
      linkId: "2631266001552",
      item: answers,
    };
  });

  const serviceAnswer = objData?.exames?.map((exame: any) => {
    const answers = [
      exame.name && {
        linkId: "8772001052232",
        answer: [{ valueString: exame.name }],
      },
      exame?.inputValue?.code && {
        linkId: "379415603318",
        answer: [{ valueString: exame.inputValue.code }],
      },
      exame?.category?.label && {
        linkId: "1383313118296",
        answer: [{ valueString: exame.category.label }],
      },
      // Adicionamos as entradas de resposta restantes se os valores não forem nulos
      exame?.condition?.label &&
        exame?.condition?.value && {
          linkId: "9571768447124",
          item: [
            {
              linkId: "8170939802572",
              answer: [{ valueString: exame.condition.label }],
            },
            {
              linkId: "4923176744451",
              answer: [{ valueString: exame.condition.value }],
            },
          ],
        },
      exame.note && {
        linkId: "3523489955010",
        answer: [{ valueString: exame.note }],
      },
    ].filter(Boolean); // Removemos valores nulos do array

    return {
      linkId: "5455138012969",
      item: answers,
    };
  });

  const conditions = objData?.condition?.length
    ? {
        linkId: "condition",
        answer: [
          objData?.condition?.map((el: any) => ({
            valueCoding: el,
          })),
        ],
      }
    : undefined;

  const certificatesAnswer = objData?.atestados?.map((atestado: any) => {
    const answers = [
      {
        linkId: "4095641279549",
        answer: [{ valueString: "Atestado comum" }],
      },
      atestado.note && {
        linkId: "3547687759041",
        answer: [{ valueString: atestado.note }],
      },
      atestado.note && {
        linkId: "8744868479001",
        answer: [{ valueString: atestado.note }],
      },
    ].filter(Boolean);
    return {
      linkId: "7695792866978",
      item: answers,
    };
  });

  if (!references)
    return [
      {
        linkId: "438586562091",
        answer: [{ valueString: objData?.nomeProtocolo }],
      },
      {
        linkId: "description",
        answer: [{ valueString: objData?.description || "" }],
      },
      {
        linkId: "referencias",
        answer: [{ valueString: objData?.referencias || "" }],
      },

      conditions,
      medicationAwnser,
      serviceAnswer,
      certificatesAnswer,
    ];
  else
    return JSON.stringify({
      resourceType: "QuestionnaireResponse",
      subject: {
        reference: `Practitioner/${oids.cpf}-${references.practitionerId}`,
      },
      status: objData?.status,
      questionnaire: `Questionnaire/${endpoints.PROTOCOL_ID}`,
      author: {
        reference: `Organization/${references.organizationId}`,
      },
      source: {
        reference: `Practitioner/${oids.cpf}-${references.practitionerId}`,
      },
      item: [
        {
          linkId: "438586562091",
          answer: [{ valueString: objData?.nomeProtocolo }],
        },
        {
          linkId: "description",
          answer: [{ valueString: objData?.description || "" }],
        },
        {
          linkId: "referencias",
          answer: [{ valueString: objData?.referencias || "" }],
        },
        conditions,
        medicationAwnser,
        serviceAnswer,
        certificatesAnswer,
      ],
    });
}

function Condition(objData: any, encounter: any) {
  const patientId = encounter.subject;
  return JSON.stringify({
    resourceType: "Condition",
    code: {
      coding: [
        {
          system: "http://www.saude.gov.br/fhir/r4/CodeSystem/BRCID10",
          code: objData?.condition?.code,
          display: objData?.condition?.display || objData?.condition?.value,
        },
      ],
    },
    participant: [
      {
        function: {
          coding: [{ code: "informant" }],
        },
        actor: {
          reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
        },
      },
      {
        function: {
          coding: [{ code: "enterer" }],
        },
        actor: {
          reference: `Practitioner/${oids.cpf}-${objData.practitionerId}`,
          display: objData?.practitionerName,
        },
      },
    ],
    clinicalStatus: {
      coding: [{ code: objData?.clinicalStatus?.value }],
    },
    verificationStatus: {
      coding: [{ code: objData?.verificationStatus?.value }],
    },
    ...(objData?.note ? { note: [{ text: objData.note }] } : {}),
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    encounter: {
      reference: `Encounter/${encounter.id}`,
    },
    recordedDate: dayjs().toISOString(),
  });
}

function CIAP2Condition(objData: any, encounter: any) {
  const patientId = encounter.subject;
  return JSON.stringify({
    resourceType: "Condition",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/r5/StructureDefinition/FM-Condition",
      ],
    },
    clinicalStatus: {
      coding: [
        {
          system: "http://terminology.hl7.org/CodeSystem/condition-clinical",
          code: "active",
        },
      ],
    },
    verificationStatus: {
      coding: [{ code: "confirmed" }],
    },

    category: [
      {
        coding: [
          {
            system: "http://terminology.hl7.org/CodeSystem/condition-category",
            code: "problem-list-item",
          },
        ],
      },
    ],
    code: {
      ...(objData?.code && {
        coding: [
          {
            system: "https://terminologia.saude.gov.br/fhir/CodeSystem/BRCIAP2",
            code: objData?.code,
            display: objData?.display,
          },
        ],
      }),
      text: objData?.text,
    },
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    encounter: {
      reference: `Encounter/${encounter.id}`,
    },
    participant: [
      // Enfermeiro
      {
        actor: {
          reference: `Practitioner/${oids.cpf}-${objData.practitionerId}`,
          display: objData?.practitionerName,
        },
      },
    ],
  });
}

function ImmunizationNarrative(objData: any, encounter: any) {
  const patientId = encounter.subject;
  return JSON.stringify({
    resourceType: "Observation",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/r5/StructureDefinition/FM-Observation",
      ],
    },
    status: "final",
    code: {
      coding: [
        {
          system: "http://loinc.org",
          code: "11369-6",
          display: "History of Immunization Narrative",
        },
      ],
      text: "Narrativa de vacinação",
    },
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    partOf: {
      reference: `Immunization/${objData?.immunizationId}`,
    },
    encounter: {
      reference: `Encounter/${encounter.id}`,
    },
    issued: dayjs(objData?.date).toISOString(),
    performer: [
      {
        reference: `Practitioner/${oids.cpf}-${objData.practitionerId}`,
        display: objData?.practitionerName,
      },
    ],
    valueString: objData?.narrative,
  });
}

function Immunization(objData: any, encounter: any) {
  const patientId = encounter.subject;
  return JSON.stringify({
    resourceType: "Immunization",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/r5/StructureDefinition/FM-Immunization",
      ],
    },
    status: "completed",
    vaccineCode: {
      coding: [
        {
          system:
            "https://terminologia.saude.gov.br/fhir/CodeSystem/BRImunobiologico",
          code: objData?.vaccine?.code,
          display: objData?.vaccine?.display,
        },
      ],
    },
    patient: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    // um dos dois elementos "occurrence" apenas
    ...(objData?.date && {
      occurrenceDateTime: dayjs(objData?.date).toISOString(), // pode ser so o ano ou ano e mes ou data, p.e. 2028 ou 2028-06 ou 2028-06-04
    }),

    informationSource: {
      reference: {
        reference: `Practitioner/${oids.cpf}-${objData?.practitionerId}`,
        display: objData?.practitionerName,
      },
    },
    encounter: {
      reference: `Encounter/${encounter.id}`,
    },
  });
}

function ServiceRequest(objData: any, encounter: any, contained?: any) {
  const uuidValue = `${uuid.v1()}`;
  const day = dayjs().toISOString();

  const patientId = encounter.subject;
  const services = objData?.map((el: any, index: number) => {
    const auxServices = {
      encounter: encounter.id,
      authoredOn: day,
      requester: encounter.requester,
      contained,
      subject: {
        reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
      },
      condition: el?.condition,
      note: el?.note,
      categoryCode: el?.category?.code,
      categoryDisplay: el?.category?.display,
      concept: {
        coding: [
          {
            system:
              "https://fluxmed.com.br/fhir/r5/CodeSystem/TUSSProcedimentosEventos",
            code: el?.inputValue?.code,
            display: el?.name,
          },
        ],
      },
      medicatonRef: el?.medication,
      procedure: el.type === "procedure" ? true : false,
    };

    return {
      resource: {
        authoredOn: auxServices?.authoredOn,
        resourceType: "ServiceRequest",
        status: "active",
        identifier: [
          {
            value: `${uuidValue}.${index + 1}`,
            system: "urn:ietf:rfc:3986",
          },
        ],
        contained: contained,
        requisition: {
          value: `${uuidValue}`,
          system: "urn:ietf:rfc:3986",
        },
        reason: {
          concept: {
            coding: [auxServices?.condition],
          },
        },
        requester: auxServices.requester,
        subject: auxServices.subject,
        encounter: {
          reference: `Encounter/${auxServices.encounter}`,
        },
        code: {
          concept: auxServices.concept,
        },
        ...(auxServices.note && {
          note: { text: auxServices.note },
        }),
        category: [
          auxServices.categoryDisplay
            ? {
                coding: [
                  {
                    ...(auxServices.categoryCode && {
                      system: "http://snomed.info/sct",
                      code: auxServices.categoryCode,
                    }),
                    display: auxServices.categoryDisplay,
                  },
                ],
              }
            : null,
          auxServices.procedure === true && { text: "procedure" },
        ].filter(Boolean),
        intent: "order",
      },
      request: {
        url: "ServiceRequest",
        method: "POST",
      },
    };
  });

  return JSON.stringify({
    resourceType: "Bundle",
    type: "transaction",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/StructureDefinition/ServiceRequestBundle-1.0",
      ],
    },
    entry: services,
  });
}

function MedicationRequest(objData: any, encounter?: any, source?: any) {
  const uuidValue = `${uuid.v1()}`;
  const day = dayjs().toISOString();

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  function removerNumerosIniciais(string: string) {
    return string?.replace(/^\d+\s*/, "");
  }

  const patientId = encounter?.subject || source?.subject;
  const medications = objData?.map((el: any, index: number) => {
    const auxMedication = {
      encounter: encounter?.id,
      requester: encounter?.requester,
      subject: {
        reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
      },

      note: el.note,
      period: el.time,
      frequency: el.frequency,
      quantity: el.quantity,
      dosage: !!(el.frequency || el.period),
      form: el.formValue,
      concept: {
        ...(el.code && el.code),
        text: el.name,
      },
      medicatonRef: el.medication,
    };
    return {
      resource: {
        resourceType: "MedicationRequest",
        status: source?.subject ? "active" : "draft",
        identifier: [
          {
            value: `${uuidValue}.${index + 1}`,
            system: "urn:ietf:rfc:3986",
          },
        ],
        groupIdentifier: {
          value: `${uuidValue}`,
          system: "urn:ietf:rfc:3986",
        },
        ...(auxMedication.requester &&
          !source?.subject && {
            requester: auxMedication.requester,
          }),

        informationSource: [
          {
            ...(!auxMedication.requester &&
              source?.subject && {
                reference: `Patient/${oids.cpf}-${patientId}`,
              }),
          },
          {
            reference: auxMedication?.requester?.reference,
            display: auxMedication?.requester?.display,
          },
        ],

        subject: auxMedication.subject,
        ...(auxMedication.encounter && {
          encounter: {
            reference: `Encounter/${auxMedication.encounter}`,
          },
        }),

        medication: {
          ...(auxMedication.concept && {
            concept: auxMedication.concept,
          }),
        },
        ...(auxMedication.note && {
          note: { text: auxMedication.note },
        }),
        ...(day && {
          effectiveDosePeriod: {
            start: day,
            ...(auxMedication?.period?.value && {
              end: dayjs(day)
                ?.add(auxMedication?.period?.value, "days")
                ?.toISOString(),
            }),
          },
        }),
        ...(auxMedication.dosage && {
          dosageInstruction: [
            {
              timing: {
                repeat: {
                  // count: 2,
                  periodUnit: "h",
                  frequency: 1,
                  // timeOfDay: ["08:00:00"],
                  // dayOfWeek: ["mon"],
                  period: auxMedication?.frequency?.value,
                  duration: auxMedication?.period?.value, // Duração total do tratamento
                  durationUnit: "d",
                },
              },
              doseAndRate: [
                {
                  type: {
                    coding: [
                      {
                        system:
                          "http://www.fluxmed.com.br/codesystem/dose-type",
                        code: auxMedication.form?.value,
                        display: auxMedication.form?.label,
                      },
                    ],
                    text: auxMedication.form?.label,
                  },
                  ...(auxMedication?.quantity && {
                    doseQuantity: {
                      value: extrairNumero(
                        auxMedication.quantity?.label || auxMedication.quantity
                      ),
                      unit:
                        removerNumerosIniciais(
                          auxMedication.quantity?.label ||
                            auxMedication.quantity
                        ) || auxMedication.form?.label,
                    },
                  }),
                },
              ],
            },
          ],
        }),
        intent: "order",
      },
      request: {
        url: "MedicationRequest",
        method: "POST",
      },
    };
  });

  return JSON.stringify({
    resourceType: "Bundle",
    type: "transaction",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/StructureDefinition/MedicationRequestBundle-1.0",
      ],
    },
    entry: medications,
  });
}

function MedicationStatement(
  objData: any,
  encounter?: any,
  patientIdentifier?: any
) {
  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  function removerNumerosIniciais(string: string) {
    return string?.replace(/^\d+\s*/, "");
  }

  const patientId = encounter ? encounter.subject : patientIdentifier;

  const auxMedication = {
    ...(encounter && { encounter: encounter.id }),
    subject: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    ...(objData?.time && { period: objData?.time }),
    frequency: objData?.frequency,
    quantity: objData?.quantity,
    ...((objData?.frequency || objData?.period) && {
      dosage: !!(objData?.frequency || objData?.period),
    }),
    ...(objData?.form && { form: objData?.form }),
    concept: {
      ...(objData?.medication?.code && objData?.medication?.code),
      text: objData?.medication?.name || objData.medication,
    },
    ...(objData.note && { note: objData?.note }),
  };

  const medicationStatement = {
    resourceType: "MedicationStatement",
    status: "recorded",
    dateAsserted: objData?.dateAssert || dayjs().toISOString(),
    subject: auxMedication?.subject,
    ...(auxMedication?.encounter && {
      encounter: {
        reference: `Encounter/${auxMedication?.encounter}`,
      },
    }),
    informationSource: {
      reference: `Patient?identifier=urn:oid:${oids.cpf}|${patientId}`,
    },
    medication: {
      ...(auxMedication?.concept && {
        concept: auxMedication.concept,
      }),
    },
    ...(auxMedication.dosage && {
      dosage: [
        {
          timing: {
            repeat: {
              // count: 2,
              periodUnit: "h",
              frequency: 1,
              // timeOfDay: ["08:00:00"],
              // dayOfWeek: ["mon"],
              period: auxMedication?.frequency?.value,
              duration: auxMedication?.period?.value, // Duração total do tratamento
              durationUnit: "d",
            },
          },
          doseAndRate: [
            {
              type: {
                coding: [
                  {
                    system: "http://www.fluxmed.com.br/codesystem/dose-type",
                    code: auxMedication.form?.value,
                    display: auxMedication.form?.label,
                  },
                ],
                text: auxMedication.form?.label,
              },
              ...(auxMedication?.quantity && {
                doseQuantity: {
                  value: extrairNumero(
                    auxMedication.quantity?.label || auxMedication.quantity
                  ),
                  unit:
                    removerNumerosIniciais(
                      auxMedication.quantity?.label || auxMedication.quantity
                    ) || auxMedication.form?.label,
                },
              }),
            },
          ],
        },
      ],
    }),
    ...(auxMedication.note && {
      note: [
        {
          text: auxMedication.note,
        },
      ],
    }),
  };

  return JSON.stringify(medicationStatement);
}

function DocumentReferenceJson(objData: any, encounter: any, refs: any) {
  const uuidValue = `${uuid.v1()}`;
  const day = dayjs().toISOString();

  const patientId = encounter.subject;

  return JSON.stringify({
    meta: {
      profile: [
        "http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle",
      ],
    },
    resourceType: "Bundle",
    type: "transaction",
    entry: [
      {
        resource: {
          resourceType: "DocumentReference",
          id: uuidValue,
          contained: [
            {
              resourceType: "Patient",
              id: "patient1",
              identifier: [
                {
                  system: `urn:oid:${oids.cpf}`,
                  value: patientId,
                },
              ],
            },
          ],
          identifier: [
            {
              system: "urn:ietf:rfc:3986",
              value: `urn:oid:1.3.6.1.4.1.54413.3.2.3.${patientId}.10.${uuidValue}.2`,
            },
            {
              use: "official",
              system: "urn:ietf:rfc:3986",
              value: `urn:uuid:${uuidValue}`,
            },
          ],
          basedOn: refs,
          status: "current",
          type: {
            coding: [
              {
                system: "urn:oid:1.3.6.1.4.1.54413.3.3.6.16",
                code: objData.codeValue,
                display: objData.codeDisplay,
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  system: "urn:oid:1.3.6.1.4.1.54413.3.3.6.16",
                  code: objData.categoryValue,
                  display: objData.categoryDisplay,
                },
              ],
            },
          ],
          subject: {
            reference: `Patient/${oids.cpf}-${patientId}`,
          },
          context: [
            {
              reference: `Encounter/${encounter?.id}`,
            },
          ],
          practiceSetting: {
            coding: [
              {
                system: objData?.servicePratice?.system,
                code: objData?.servicePratice?.code,
                display: objData?.servicePratice?.display,
              },
            ],
          },
          period: {
            start: objData?.time?.start,
            end: objData?.time?.end,
          },
          date: day,
          author: [
            {
              reference: "#patient",
            },
            {
              reference: `Patient/${oids.cpf}-${patientId}`,
            },
          ],
          securityLabel: [
            {
              coding: [
                {
                  system: "urn:oid:2.16.840.1.113883.5.25",
                  code: "N",
                  display: "Normal",
                },
              ],
            },
          ],
          content: [
            {
              attachment: {
                contentType: "application/pdf",
                url: `Binary/1.3.6.1.4.1.54413.3.2.3.${patientId}.10.${uuidValue}.2`,
                creation: day,
                language: "pt-BR",
              },
              profile: [
                {
                  valueCoding: {
                    system: "urn:oid:1.3.6.1.4.1.54413.3.3.6.16",
                    code: "application/pdf",
                  },
                },
              ],
            },
          ],
        },
        request: {
          method: "PUT",
          url: `DocumentReference/${uuidValue}`,
        },
      },
      {
        resource: {
          resourceType: "Binary",
          id: `1.3.6.1.4.1.54413.3.2.3.${patientId}.10.${uuidValue}.2`,
          contentType: "application/pdf",
          data: objData.base64,
        },
        request: {
          method: "PUT",
          url: `Binary/1.3.6.1.4.1.54413.3.2.3.${patientId}.10.${uuidValue}.2`,
        },
      },
    ],
  });
}

function DocumentReference(
  objData: any,
  encounter: any,
  refs: any,
  signed?: boolean,
  uuidDefault?: any,
  idValue?: any
) {
  const uuidValue = `${uuidDefault || uuid.v1()}`;
  const day = dayjs().toISOString();

  const patientId = encounter.subject;
  const practitionerId = objData?.practitioner?.id?.split("-")?.[1];
  const practitionerRole = encounter?.requester?.reference;
  const organizationId = objData?.organization?.id?.split("-")?.[1];

  const auxTag = organizationId
    ? ` <fhir:meta>
  <fhir:tag>
    <fhir:system value="urn:oid:2.16.76.1.3.3"/>
    <fhir:code value="${organizationId}"/>
  </fhir:tag>
</fhir:meta>`
    : " ";

  const auxContained =
    practitionerId && organizationId
      ? `<fhir:contained>
<fhir:Practitioner>
  <fhir:id value="practitioner1"/>
  <fhir:identifier>
    <fhir:system value="urn:oid:${oids.cpf}"/>
    <fhir:value value="${practitionerId}"/>
  </fhir:identifier>
  <fhir:name>
    <fhir:given value="${objData?.practitioner?.name}"/>
  </fhir:name>
</fhir:Practitioner>
</fhir:contained>
<fhir:contained>
    <fhir:Organization>
      <fhir:id value="organization1"/>
      <fhir:identifier>
        <fhir:system value="urn:oid:2.16.76.1.3.3"/>
        <fhir:value value="${organizationId}"/>
      </fhir:identifier>
      <fhir:name value="${objData?.organization?.name}"/>
      <fhir:alias value="${objData?.organization?.alias}"/>
    </fhir:Organization>
  </fhir:contained>
`
      : ` <fhir:contained>
    <fhir:Patient>
      <fhir:id value="patient1"/>
      <fhir:identifier>
        <fhir:system value="urn:oid:${oids.cpf}"/>
        <fhir:value value="${patientId}"/>
      </fhir:identifier>
    </fhir:Patient>
  </fhir:contained>`;

  const auxAuthor =
    practitionerId && organizationId && practitionerRole
      ? ` <fhir:author>
    <fhir:reference value="#practitioner1"/>
  </fhir:author>
  <fhir:author>
    <fhir:reference value="Practitioner/${oids.cpf}-${practitionerId}"/>
  </fhir:author>
  <fhir:author>
    <fhir:reference value="${practitionerRole}"/>
  </fhir:author>
  <fhir:author>
    <fhir:reference value="#organization1"/>
  </fhir:author>
  <fhir:author>
    <fhir:reference value="Organization/2.16.76.1.3.3-${organizationId}"/>
  </fhir:author>`
      : ` <fhir:author>
  <fhir:reference value="#patient"/>
</fhir:author>
<fhir:author>
  <fhir:reference value="Patient/${oids.cpf}-${patientId}"/>
</fhir:author>`;

  const auxRelatesTo = objData?.relatesTo
    ? `<fhir:relatesTo>
      <fhir:code value="replaces"/>
      <fhir:target>
         <fhir:reference value="DocumentReference?${objData?.relatesTo}"/>
      </fhir:target>
</fhir:relatesTo>`
    : " ";

  const securityLabel = signed
    ? `
<fhir:securityLabel>
 <fhir:coding>
   <fhir:system value="http://hl7.org/fhir/ValueSet/signature-type"/>
  <fhir:code  value="ProofOfOrigin"/>
  <fhir:display value="Proof of origin"/>
</fhir:coding>
</fhir:securityLabel>`
    : `<fhir:securityLabel>
        <fhir:coding>
          <fhir:system value="2.16.840.1.113883.5.25"/>
          <fhir:code value="N"/>
          <fhir:display value="Normal"/>
        </fhir:coding>
      </fhir:securityLabel>`;

  const documentReference = `<fhir:entry>
  <fhir:resource>
    <fhir:DocumentReference>
      <fhir:id value="${uuidValue}"/>
      ${auxTag}
      ${auxContained}
      <fhir:identifier>
        <fhir:system value="urn:ietf:rfc:3986"/>
        <fhir:value value="urn:oid:1.3.6.1.4.1.54413.3.2.3.${
          organizationId || patientId
        }.10.${idValue}.2"/>
      </fhir:identifier>
      <fhir:identifier>
        <fhir:use value="official"/>
        <fhir:system value="urn:ietf:rfc:3986"/>
        <fhir:value value="urn:uuid:${uuidValue}"/>
      </fhir:identifier>
       ${refs || " "}
      <fhir:status value="current"/>
      <fhir:type>
        <fhir:coding>
          <fhir:system value="1.3.6.1.4.1.54413.3.3.6.16"/>
          <fhir:code value="${objData.codeValue}"/>
          <fhir:display value="${objData.codeDisplay}"/>
        </fhir:coding>
      </fhir:type>
      <fhir:category>
        <fhir:coding>
          <fhir:system value="1.3.6.1.4.1.54413.3.3.6.16"/>
          <fhir:code value="${objData.categoryValue}"/>
          <fhir:display value="${objData.categoryDisplay}"/>
        </fhir:coding>
      </fhir:category>
      <fhir:subject>
        <fhir:reference value="Patient/${oids.cpf}-${patientId}"/>
      </fhir:subject>
      <fhir:context>
        <fhir:reference value="Appointment/${encounter.appointmentId}"/>
      </fhir:context>
      <fhir:context>
        <fhir:reference value="Encounter/${encounter?.id}"/>
      </fhir:context>
      <fhir:practiceSetting>
        <fhir:coding>
          <fhir:system value="${objData?.servicePratice?.system}"/>
          <fhir:code value="${objData?.servicePratice?.code}"/>
          <fhir:display value="${objData?.servicePratice?.display}"/>
        </fhir:coding>
      </fhir:practiceSetting>
      <fhir:period>
        <fhir:start value="${objData?.time?.start}"/>
        <fhir:end value="${objData?.time?.end}"/>
      </fhir:period>
      <fhir:date value="${day}"/>
     ${auxAuthor}
     
     ${auxRelatesTo}

     ${securityLabel}
      
      <fhir:content>
        <fhir:attachment>
          <fhir:contentType value="application/pdf"/>
          <fhir:language value="pt-BR"/>
          <fhir:url value="Binary/1.3.6.1.4.1.54413.3.2.3.${
            organizationId || patientId
          }.10.${idValue}.2"/>
          <fhir:creation value="${day}"/>
        </fhir:attachment>
        <fhir:profile>
          <fhir:valueCoding>
            <fhir:system value="1.3.6.1.4.1.54413.3.3.6.16"/>
            <fhir:code value="application/pdf"/>
          </fhir:valueCoding>
        </fhir:profile>
      </fhir:content>
    </fhir:DocumentReference>
  </fhir:resource>
  <fhir:request>
    <fhir:method value="PUT"/>
    <fhir:url value="DocumentReference/${uuidValue}"/>
  </fhir:request>
</fhir:entry>`;

  const binary = `<fhir:entry>
<fhir:resource>
  <fhir:Binary>
    <fhir:id value="1.3.6.1.4.1.54413.3.2.3.${
      organizationId || patientId
    }.10.${idValue}.2"/>
    <fhir:contentType value="application/pdf"/>
    <fhir:data value="${objData.base64}"/>
  </fhir:Binary>
</fhir:resource>
<fhir:request>
  <fhir:method value="PUT"/>
  <fhir:url value="Binary/1.3.6.1.4.1.54413.3.2.3.${
    organizationId || patientId
  }.10.${idValue}.2"/>
</fhir:request>
</fhir:entry>`;

  const bundleXML = `<fhir:Bundle
	xmlns:fhir="http://hl7.org/fhir">
	<fhir:meta>
		<fhir:profile value="http://ihe.net/fhir/StructureDefinition/IHE_MHD_Provide_Comprehensive_DocumentBundle"/>
	</fhir:meta>
	<fhir:type value="transaction"/>
	${documentReference}
  ${binary}

</fhir:Bundle>`;

  return bundleXML;
}

function PatientSummary(identifier: string, entries?: string[]) {
  const numberOfDocsToRequest = 100;
  const bundleEntries = [
    // {
    //   request: {
    //     method: "GET",
    //     url: `Observation?code=http://loinc.org|55284-4&subject=Patient/${oids.cpf}-${identifier}&status=final&_sort=-date&_count=1`,
    //   },
    // },
    // {
    //   request: {
    //     method: "GET",
    //     url: `Observation?code=http://loinc.org|29463-7&subject=Patient/${oids.cpf}-${identifier}&status=final&_sort=-date&_count=1`,
    //   },
    // },
    // {
    //   request: {
    //     method: "GET",
    //     url: `Observation?code=http://loinc.org|8302-2&subject=Patient/${oids.cpf}-${identifier}&status=final&_sort=-date&_count=1`,
    //   },
    // },
    {
      request: {
        method: "GET",
        url: `Observation?subject=Patient/${oids.cpf}-${identifier}&status=final&_sort=-_lastUpdated&_count=${numberOfDocsToRequest}`,
      },
    },
    {
      request: {
        method: "GET",
        url: `Condition?subject=Patient/${oids.cpf}-${identifier}&verification-status=unconfirmed,provisional,confirmed,refuted&_sort=-recorded-date&_count=${numberOfDocsToRequest}`,
      },
    },
    {
      request: {
        method: "GET",
        url: `Immunization?patient=Patient/${oids.cpf}-${identifier}&status=completed&_sort=-_lastUpdated&_count=${numberOfDocsToRequest}`,
      },
    },
    {
      request: {
        method: "GET",
        url: `Encounter?subject=Patient/${oids.cpf}-${identifier}&_sort=-date&_count=${numberOfDocsToRequest}`,
      },
    },
    // {
    //   request: {
    //     method: "GET",
    //     url: `Condition?code=2.16.840.1.113883.6.139|,http://www.saude.gov.br/fhir/r4/CodeSystem/BRCIAP2|&subject=Patient/${oids.cpf}-${identifier}&_sort=-recorded-date&_count=${numberOfDocsToRequest}`,
    //   },
    // },
    {
      request: {
        method: "GET",
        url: `AllergyIntolerance?patient=Patient/${oids.cpf}-${identifier}&verification-status=unconfirmed,presumed,confirmed,refuted&_sort=-date&_count=${numberOfDocsToRequest}`,
      },
    },
    // {
    //   request: {
    //     method: "GET",
    //     url: `Procedure?patient=Patient/${oids.cpf}-${identifier}&status=completed&_sort=-date&_count=${numberOfDocsToRequest}`,
    //   },
    // },
    {
      request: {
        method: "GET",
        url: `MedicationRequest?subject=Patient/${oids.cpf}-${identifier}&_sort=-_lastUpdated&_count=${numberOfDocsToRequest}&status=active,on-hold,ended,stopped,completed,cancelled,draft,unknown`,
      },
    },
    {
      request: {
        method: "GET",
        url: `CarePlan?subject=Patient/${oids.cpf}-${identifier}&status=active&_count=${numberOfDocsToRequest}&_sort=-_lastUpdated`,
      },
    },
    // {
    //   request: {
    //     method: "GET",
    //     url: `MedicationStatement?subject=Patient/${oids.cpf}-${identifier}&_count=${numberOfDocsToRequest}&_sort=-_lastUpdated`,
    //   },
    // },
    // {
    //   request: {
    //     method: "GET",
    //     url: `DocumentReference?subject=Patient/${oids.cpf}-${identifier}&type:coding=Sum%C3%A1rioAlta.v4.0,Sum%C3%A1rio%20de%20Alta%20v1.0&_sort=-period&_count=${numberOfDocsToRequest}`,
    //   },
    // },
    {
      request: {
        method: "GET",
        url: `ServiceRequest?subject=Patient/${oids.cpf}-${identifier}&status=active,completed&_sort=-authored&_count=${numberOfDocsToRequest}`,
      },
    },
    {
      request: {
        method: "GET",
        url: `/DocumentReference?subject=Patient/${oids.cpf}-${identifier}&status=current&category=atestado&_count=100&_sort=-_lastUpdated&_include=DocumentReference:based-on`,
      },
    },
    {
      request: {
        method: "GET",
        url: `/DocumentReference?subject=Patient/${oids.cpf}-${identifier}&status=current&category=prescricao&_count=100&_sort=-_lastUpdated&_include=DocumentReference:based-on`,
      },
    },
    {
      request: {
        method: "GET",
        url: `/DocumentReference?subject=Patient/${oids.cpf}-${identifier}&status=current&category=service-request&_count=100&_sort=-_lastUpdated&_include=DocumentReference:based-on`,
      },
    },
    {
      request: {
        method: "GET",
        url: `/DocumentReference?subject=Patient/${oids.cpf}-${identifier}&status=current&category=resultado&_count=100&_sort=-_lastUpdated`,
      },
    },
    {
      request: {
        method: "GET",
        url: `/QuestionnaireResponse?subject=Patient/${oids.cpf}-${identifier}&_count=100&_sort=-_lastUpdated`,
      },
    },
  ];

  const filterEntries = bundleEntries?.filter((e: any) => {
    const verifis = entries?.some((ele: any) => e?.request?.url?.includes(ele));
    return verifis;
  });

  return JSON.stringify({
    resourceType: "Bundle",
    meta: {
      profile: ["http://ehrrunner.com/fhir/StructureDefinition/PatientSummary"],
    },
    entry: entries?.length ? filterEntries : bundleEntries,
  });
}

function Email(objData: IEmail) {
  return {
    cpf: objData.identifier,
    email: objData.email,
  };
}

function Patch(objData: IPatch) {
  return objData.operations?.map(({ op, path, value }) => ({
    op: op,
    path: path,
    value: value,
  }));
}

export const auxiliarData = {
  PersonAux,
  Immunization,
  ImmunizationNarrative,
  Practitioner,
  AllergyIntolerance,
  Condition,
  CIAP2Condition,
  PractitionerRole,
  PatchPractitionerRole,
  Location,
  HealthcareService,
  DocumentReference,
  DocumentReferenceJson,
  ServiceRequest,
  MedicationRequest,
  MedicationStatement,
  ProtocolResponse,
  PatientSummary,
  Schedule,
  Patient,
  RelatedPerson,
  Appointment,
  Encounter,
  Email,
  Slot,
  Patch,
};
