import { NoDataWarning } from "@/components/common";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

const STATUS_DISPLAY = {
  alterado: "Alterado",
  normal: "Normal",
  not_evaluated: "Não avaliado",
} as const;

const STATUS_COLORS = {
  alterado: "#FEE2A9",
  normal: "#E6F7F5",
  not_evaluated: "#F5F5F5",
} as const;

interface ExamesHistoryAccordionProps {
  data: any;
  index: number;
  setFilter: (value: number) => void;
  isMobile?: boolean;
}

export function ExamesHistoryAccordion({
  data,
  index,
  setFilter,
  isMobile = false,
}: ExamesHistoryAccordionProps) {
  const [expanded, setExpanded] = useState(index === 0);

  const handleChange = (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const getStatusDisplay = (code: keyof typeof STATUS_DISPLAY) =>
    STATUS_DISPLAY[code] || "Não avaliado";

  const getStatusColor = (code: keyof typeof STATUS_COLORS) =>
    STATUS_COLORS[code] || "#F5F5F5";

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        width: "100%",
        minWidth: isMobile ? "100%" : "300px",
        flexGrow: 1,
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
        "& .MuiAccordionDetails-root": {
          maxHeight: "calc(100vh - 300px)",
          overflow: "auto",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Stack direction="row" width="100%" spacing={2}>
          <Chip
            label={data?.displayInfo?.healthcare || "Sem especialidade"}
            size="small"
            sx={{
              color: data?.displayInfo?.healthcare
                ? "neutral700.main"
                : "neutral500.main",
              fontWeight: 500,
              backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
            }}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        {data?.filterItens?.item?.map((el: any) => {
          const hasAlterations =
            el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString !==
            "undefined";

          return (
            <Stack
              key={el.text}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              height="50px"
              direction="row"
              sx={{ mt: 1 }}
            >
              <Stack p={1}>
                <Typography fontWeight={500}>{el?.text}</Typography>
                {hasAlterations && (
                  <Tooltip
                    title={el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString}
                    placement="right"
                  >
                    <Typography>
                      {el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString.substring(
                        0,
                        80
                      )}
                      {el?.answer?.[0]?.item?.[0]?.answer?.[0]?.valueString
                        ?.length > 80 && "..."}
                    </Typography>
                  </Tooltip>
                )}
              </Stack>

              <Stack
                width="124px"
                borderRadius="5px"
                justifyContent="center"
                alignItems="center"
                padding={1}
                sx={{
                  background: getStatusColor(
                    el?.answer?.[0]?.valueCoding?.code
                  ),
                  ":hover": {
                    border: "1px solid",
                    borderColor: "neutral400.main",
                  },
                }}
              >
                <Typography fontWeight={500} sx={{ color: "neutral600.main" }}>
                  {getStatusDisplay(el?.answer?.[0]?.valueCoding?.code)}
                </Typography>
              </Stack>
            </Stack>
          );
        }) || <NoDataWarning message="Nenhum exame encontrado" />}

        <Divider sx={{ my: 2 }} />
        <Typography
          color="neutral700.main"
          sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
        >
          Dr. {data?.displayInfo?.practitioner}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
