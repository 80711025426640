import { IconButton, TableCell, Tooltip } from "@mui/material";
import { ContentPaste } from "@mui/icons-material";
import { useSessionContext } from "../../../../../contexts/SessionContext";

export function ProblemsActions({
  row,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { access } = useSessionContext();
  return (
    <TableCell align="center">
      {verifyVisualizer &&
        verifyVisualizer(row?.encounterId) &&
        access?.type === "professional" && (
          <Tooltip title="Visualizar atendimento">
            <IconButton
              onClick={() => {
                openVisualizer(row?.encounterId);
              }}
            >
              <ContentPaste />
            </IconButton>
          </Tooltip>
        )}
    </TableCell>
  );
}
