import { Stack, IconButton, Collapse } from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { AnamneseHistory } from "./AnamneseHistory";
import { scrollbarStyle } from "../../scrollbarStyle";

interface AnamneseHistoryViewProps {
  selectedField: string;
  isMobile?: boolean;
}

export function AnamneseHistoryView({
  selectedField,
  isMobile = false,
}: AnamneseHistoryViewProps) {
  const [isExpanded, setIsExpanded] = useState(!isMobile);

  return (
    <Stack
      width={isMobile ? "100%" : "50%"}
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      {isMobile && (
        <IconButton
          onClick={() => setIsExpanded(!isExpanded)}
          size="small"
          sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}

      <Collapse
        in={isExpanded}
        sx={{
          maxHeight: "59vh",
          overflow: "auto",
          ...scrollbarStyle,
        }}
      >
        <Stack height="100%">
          <AnamneseHistory selectedField={selectedField} isMobile={isMobile} />
        </Stack>
      </Collapse>
    </Stack>
  );
}
