import { PrivateMainLayout } from "../../components/layout";
import { Box, Paper, styled } from "@mui/material";

import { ClinicalVisualization } from "./components/ClinicalVisualization/ClinicalVisualization";
import { EditQuestionnaireContextProvider } from "../../contexts/EditQuestionnaireContext";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "100%",
  },
}));

export function Prototype() {
  return (
    <PrivateMainLayout>
      <CustomBox>
        <Paper
          sx={{
            display: "grid",
            width: "100%",
            height: "100%",
            padding: "1%",
            justifyItems: "center",
            minHeight: "600px",
            borderRadius: "20px",
          }}
        >
          <EditQuestionnaireContextProvider>
            <ClinicalVisualization />
          </EditQuestionnaireContextProvider>
        </Paper>
      </CustomBox>
    </PrivateMainLayout>
  );
}
