import { Box, Stack, Tab, Tabs } from "@mui/material";
import { ReactNode, SyntheticEvent, useState } from "react";
import { scrollbarStyle } from "../scrollbarStyle";
import { AnthropometricFields } from "../Anthropometrics/AnthropometricFields";
import { VitalSignsFields } from "../VitalSigns/VitalSignsFields";
import { ImmunizationForm } from "../Immunizations/components/ImmunizationForm";
import { AnthropometricHistory } from "../Anthropometrics/AnthropometricHistory";
import { VitalSignsHistory } from "../VitalSigns/VitalSignsHistory";
import { ImmunizationHistory } from "../Immunizations/ImmunizationHistory";
import { useForm, useFormState } from "react-hook-form";
import {
  CircularLoading,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components/common";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useSessionContext } from "@/contexts/SessionContext";
import { PostImmunizationNarrative } from "@/services/fhir/patientsummary/immunization/PostImmunizationNarrative";
import { PostImmunization } from "@/services/fhir/patientsummary/immunization/PostImmunization";
import { useMedicalCareContext } from "@/contexts/MedicalCareContext";
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const typeObjectiveData = {
  anthropometrics: 0,
  vitalSigns: 1,
  immunizations: 2,
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`objective-tabpanel-${index}`}
      aria-labelledby={`objective-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `objective-tab-${index}`,
    "aria-controls": `objective-tabpanel-${index}`,
  };
}

export function ObjectiveData() {
  const { state } = useLocation();
  const { user } = useSessionContext();
  const { setRefetch, loadingSummary } = useMedicalCareContext();

  const [tabValue, setTabValue] = useState<number>(
    typeObjectiveData.anthropometrics
  );
  const references = state?.encounterReference;
  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;
  const [editImmunization, setEditImmunization] = useState<any>(null);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  const { dirtyFields } = useFormState({ control });

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    reset();
    setEditImmunization(null);
  };

  const onSubmitImmunization = async (data: any) => {
    try {
      const immunizationResponse = await PostImmunization(
        {
          ...data?.immunization,
          practitionerId: user?.username,
          practitionerName: user?.name,
        },
        references
      );

      if (data?.immunization?.narrative?.length && immunizationResponse?.id) {
        await PostImmunizationNarrative(
          {
            ...data?.immunization,
            immunizationId: immunizationResponse?.id,
            practitionerId: user?.username,
            practitionerName: user?.name,
          },
          references
        );
      }
      toast.success("Imunização registrada!");
      reset();
    } catch {
      toast.error("Erro ao registrar imunização!");
    } finally {
      reset({
        immunization: {
          vaccine: null,
          date: null,
          narrative: "",
        },
      });

      setRefetch(true);
    }
  };

  const onEditImmunization = async (data: any) => {
    setLoadingEdit(true);
    try {
      // TODO: Implementar PutImmunization
      console.log("Dados a serem editados:", data);
      toast.success("Imunização atualizada!");
    } catch (err) {
      console.log("err", err);
      toast.error("Erro ao atualizar imunização!");
    } finally {
      setLoadingEdit(false);
      reset();
      setEditImmunization(null);
    }
  };

  return (
    <>
      <Stack direction="row" width="100%" height="60vh" spacing={2}>
        <Stack
          width="50%"
          overflow="auto"
          sx={{
            ...scrollbarStyle,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="objective data tabs"
            >
              <Tab
                label="Antropométricos"
                {...a11yProps(typeObjectiveData.anthropometrics)}
              />
              <Tab
                label="Sinais Vitais"
                {...a11yProps(typeObjectiveData.vitalSigns)}
              />
              <Tab
                label="Imunizações"
                {...a11yProps(typeObjectiveData.immunizations)}
              />
            </Tabs>
          </Box>

          <CustomTabPanel
            value={tabValue}
            index={typeObjectiveData.anthropometrics}
          >
            <AnthropometricFields />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={typeObjectiveData.vitalSigns}>
            <VitalSignsFields />
          </CustomTabPanel>
          <CustomTabPanel
            value={tabValue}
            index={typeObjectiveData.immunizations}
          >
            <Box sx={{ p: 2 }}>
              {loadingSummary ? (
                <CircularLoading />
              ) : (
                <ImmunizationForm
                  control={control}
                  setValue={setValue}
                  editImmunization={editImmunization}
                />
              )}

              {/* Botões de ação para Imunizações */}
              {tabValue === typeObjectiveData.immunizations && (
                <Stack
                  direction="row"
                  width="100%"
                  mt={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!editImmunization ? (
                    <PrimaryButton
                      onClick={handleSubmit(onSubmitImmunization)}
                      width="fit-content"
                      height="40px"
                    >
                      Salvar
                    </PrimaryButton>
                  ) : (
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <SecondaryButton
                        width="fit-content"
                        height="40px"
                        onClick={() => {
                          reset();
                          setEditImmunization(null);
                        }}
                      >
                        Cancelar
                      </SecondaryButton>
                      <PrimaryButton
                        onClick={handleSubmit(onEditImmunization)}
                        width="fit-content"
                        height="40px"
                        loading={loadingEdit}
                        disabled={!Object.keys(dirtyFields).length}
                      >
                        Editar
                      </PrimaryButton>
                    </Stack>
                  )}
                </Stack>
              )}
            </Box>
          </CustomTabPanel>
        </Stack>

        <Stack
          width="50%"
          overflow="auto"
          sx={{
            ...scrollbarStyle,
          }}
        >
          {tabValue === typeObjectiveData.anthropometrics && (
            <AnthropometricHistory />
          )}
          {tabValue === typeObjectiveData.vitalSigns && <VitalSignsHistory />}
          {tabValue === typeObjectiveData.immunizations && (
            <ImmunizationHistory
              reset={reset}
              setEditImmunization={setEditImmunization}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
}
