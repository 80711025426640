import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { oids } from "../configs/Settings";
import {
  BundlePatientSummary,
  GetAppointment,
  GetPatient,
} from "../services/fhir";
import { useLocationContext } from "./LocationContext";
import { useSessionContext } from "./SessionContext";
import { useFhirData } from "../utils/useFhirData";

interface MedicalCareContextValue {
  setActiveStep: Function;
  activeStep: any;
  setRegisterAreaTab: Function;
  registerAreaTab: any;
  setProblems: Function;
  problems: any;

  setCondutas: Function;
  condutas: any;

  setCarePlan: Function;
  carePlan: any;

  setNewExams: Function;
  newExams: any;
  setPastExams: Function;
  pastExams: any;
  setInterventionTab: Function;
  interventionTab: any;
  setOpenDialog: Function;
  openDialog: any;
  setOpenSummary: Function;
  openSummary: any;
  loadingSummary: boolean;
  loadingAppointment: boolean;
  loadingDiagnostics: boolean;
  loadingTreatments: boolean;
  setLoadingDiagnostics: Function;
  setLoadingTreatments: Function;
  summaryData: any;
  type: any;
  patient: any;
  appointment: any;
  setType: any;
  setRefetch: any;
  control: any;
  handleSubmit: any;
  unregister: any;
  setValue: any;
  initial: any;
  clinicalLocation: fhir5.Location | undefined;
  clearRegisterData: any;
  isNursingService: boolean;
  healthcareServiceDisplay: string | undefined;
}

export const MedicalCareContext = createContext<MedicalCareContextValue>(null!);

export function MedicalCareContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { state } = useLocation();
  const { user, access } = useSessionContext();
  const { location } = useLocationContext();
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);
  const [loadingAppointment, setLoadingAppointment] = useState<boolean>(false);
  const [loadingDiagnostics, setLoadingDiagnostics] = useState<boolean>(false);
  const [loadingTreatments, setLoadingTreatments] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState();
  const [patient, setPatient] = useState<fhir5.Patient>();
  const [appointment, setAppointment] = useState<fhir5.Appointment>();

  const [newExams, setNewExams] = useState<any>([]); // Estado para armazenar os inputs dinâmicos
  const [pastExams, setPastExams] = useState<any>([]); // Estado para armazenar os inputs dinâmicos

  const [problems, setProblems] = useState<any>([]);

  const [condutas, setCondutas] = useState<any>([]);
  const [carePlan, setCarePlan] = useState<any>([""]);
  const [initial, setInitial] = useState<boolean>(true);

  const [activeStep, setActiveStep] = useState(0);
  const [registerAreaTab, setRegisterAreaTab] = useState(0);
  const [interventionTab, setInterventionTab] = useState(0);
  const [openSummary, setOpenSummary] = useState<boolean>(false);
  const [refetch, setRefetch] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [type, setType] = useState<any>(0);

  const methods = useForm();
  const { handleSubmit, control, unregister, setValue, reset } = methods;

  const clinicalLocation = location?.find((el) =>
    el?.resource?.id?.includes(
      state?.encounterReference?.participant
        ?.find((loc: any) => loc?.actor?.reference?.includes("Location"))
        .actor?.reference.split("/")[1]
    )
  )?.resource;

  useEffect(() => {
    async function GetBundle() {
      setLoadingSummary(true);
      try {
        const bundleResponse = await BundlePatientSummary({
          identifier: state?.encounterReference?.subject,
        });
        setSummaryData(bundleResponse);
      } catch (err) {
        console.log("PatientSummary error:", err);
      } finally {
        setLoadingSummary(false);
        setLoadingDiagnostics(false);
        setLoadingTreatments(false);
        setRefetch(false);
      }
    }

    if (state?.encounterReference?.subject && access?.type !== "patient") {
      GetBundle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.encounterReference?.subject, refetch]);

  function clearRegisterData() {
    setActiveStep(0);
    setRegisterAreaTab(0);
    setInterventionTab(0);
    reset();
    setNewExams([]);
    setPastExams([]);
    setProblems([]);
    setCondutas([]);
    setCarePlan([""]);
  }

  useEffect(() => {
    async function getData() {
      setLoadingAppointment(true);
      setActiveStep(0);
      try {
        if (
          appointment &&
          appointment?.id !== state?.encounterReference?.appointmentId
        ) {
          clearRegisterData();

          setInitial(true);
        } else {
          setInitial(false);
        }
        const response = await GetAppointment({
          _id: state?.encounterReference?.appointmentId,
          includePatient: true,
          actor: `Practitioner/${oids.cpf}-${user.username}`,
        });

        const mpiResponse = await GetPatient({
          identifier: state?.encounterReference?.subject,
        });

        setPatient(mpiResponse?.data);
        const auxAppointment = response?.entry?.find(
          (el: any) => el.resource.resourceType === "Appointment"
        );
        setAppointment(auxAppointment?.resource);
      } catch (err) {
        console.log("GetData MedicalCare", err);
      } finally {
        setLoadingAppointment(false);
      }
    }

    if (state?.encounterReference?.subject && access?.type !== "patient") {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.encounterReference?.subject]);

  // Lógica para verificar o tipo de serviço
  const healthcareService = useMemo(() => {
    return useFhirData.findParticipant(
      appointment?.participant,
      "HealthcareService"
    );
  }, [appointment]);

  const isNursingService = useMemo(() => {
    return healthcareService?.display === "Enfermagem";
  }, [healthcareService]);

  const contextValue = {
    activeStep,
    setActiveStep,
    control,
    handleSubmit,
    openDialog,
    setOpenDialog,
    openSummary,
    setOpenSummary,
    type,
    setType,
    setRefetch,
    summaryData,
    patient,
    appointment,
    loadingSummary,
    loadingAppointment,
    loadingDiagnostics,
    loadingTreatments,
    setLoadingTreatments,
    setLoadingDiagnostics,
    unregister,
    setValue,
    clinicalLocation,
    registerAreaTab,
    setRegisterAreaTab,
    interventionTab,
    setInterventionTab,
    newExams,
    setNewExams,
    pastExams,
    setPastExams,
    condutas,
    setCondutas,
    problems,
    setProblems,
    carePlan,
    setCarePlan,
    initial,
    clearRegisterData,
    isNursingService,
    healthcareServiceDisplay: healthcareService?.display,
  };

  return (
    <MedicalCareContext.Provider value={contextValue}>
      {children}
    </MedicalCareContext.Provider>
  );
}

export function useMedicalCareContext() {
  return useContext(MedicalCareContext);
}
