import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

type AppointmentStatusType =
  | "proposed"
  | "pending"
  | "booked"
  | "arrived"
  | "fulfilled"
  | "cancelled"
  | "noshow"
  | "entered-in-error"
  | "checked-in"
  | "waitlist";

interface IProps {
  count?: number;
  locationActors?: string;
  practitionerActor?: string;
  purposeofuse?: string;
  status?: AppointmentStatusType[];
}

/**
 * Obtém pacientes com base em seus agendamentos
 * 
 * @param count número de resultados a serem retornados (padrão: 250)
 * @param locationActors string com IDs das localizações, separados por vírgula
 * @param practitionerActor string com ID do profissional
 * @param status array com status dos agendamentos a serem filtrados
 * @returns Bundle contendo os pacientes
 */
export async function PatientBasedAppointment({
  count = 250,
  locationActors,
  practitionerActor,
  purposeofuse,
  status,
}: IProps) {
  let url = `${endpoints.SCHEDULING}/Patient?_count=${count}`;
  
  // Adiciona filtro de relacionamento com Appointment por profissional
  if (practitionerActor) {
    url = `${url}&_has:Appointment:patient:actor=${practitionerActor}`;
  }
  
  // Adiciona filtro de relacionamento com Appointment por localização
  if (locationActors) {
    url = `${url}&_has:Appointment:patient:actor=${locationActors}`;
  }
  
  // Adiciona filtro por status, se fornecido
  if (status && status.length > 0) {
    url = `${url}&_has:Appointment:patient:status=${status.join(",")}`;
  }

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    ...(purposeofuse && { purposeofuse: purposeofuse }),
  };

  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`PatientBasedAppointment: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PatientBasedAppointment ", error);
      // toast.error(`PatientBasedAppointment Error: ${error?.response?.status}`);
      throw new Error();
    });
  
  if (response) return response;
} 