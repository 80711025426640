import { Stack, Typography } from "@mui/material";

import { InfoOutlined } from "@mui/icons-material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PrimaryButton, SecondaryButton } from "../../../../components/common";
import { useInterventionsContext } from "../../../../contexts/InterventionsContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PostMedicationRequest } from "../../../../services/fhir";
import { MedicationForm } from "../MedicationForm";
import { TratamentoHistory } from "./TratamentoHistory";

export function TratamentosDeclarado() {
  const { state } = useLocation();
  const { user } = useSessionContext();
  const { setRefetch, setLoadingTreatments, isNursingService } =
    useMedicalCareContext();
  const { action, medicationOptions } = useInterventionsContext();

  const [tabValue, setTabValue] = useState(0);
  const [render, setRender] = useState<boolean>(false);
  const [editTreatment, setEditTreatment] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingTreatment, setLoadingTreatment] = useState<boolean>(false);
  const references = state?.encounterReference;

  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;

  const onSubmit = async (data: any) => {
    setLoadingTreatments(true);
    if (data && references) {
      try {
        const auxForm = [
          data?.quantity && `${data?.quantity}`,
          data?.form?.label && `${data?.form?.label}`,
          data?.frequency?.label && `a cada ${data?.frequency?.label}`,
          data?.time?.label && `por ${data?.time?.label}`,
        ]
          ?.filter((e: string) => e)
          ?.join(" ");
        const auxItem = {
          name: data?.medication?.name,
          label: data?.medication?.label,
          tag: data?.medication?.tag,
          inputValue: medicationOptions.find(
            (e: any) => e.value === data.medication.value
          ) || {
            name: data.medication.name,
            value: data.medication.name,
            label: data.medication.name,
          },
          quantity: data?.quantity,
          frequency: data?.frequency,
          formValue: data?.form,
          time: data?.time,
          note: data?.note,
          form: auxForm,
          type: "medication",
          code: data?.medication?.code,
          tabValue: `${tabValue}`,
          practitionerId: user?.username,
          practitionerName: user?.name,
        };
        await PostMedicationRequest([auxItem], references, {
          subject: references?.subject,
        });

        if (!isNursingService) {
          action(auxItem);
        }
      } catch (err) {
        toast.error("Não foi possível registrar o tratamento, tente novamente");
      } finally {
        setRender(false);
        setRefetch((prev: boolean) => !prev);
        reset();
      }
    }
  };

  const onEditableTreatment = (data: any) => {
    console.log("dataEditable: ", data);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setEditTreatment(null);
    reset();
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!render) setRender(true);
  }, [render]);

  return (
    <>
      <Stack width="100%" maxHeight="60vh" overflow="auto">
        <Stack
          direction="row"
          //bgcolor="primary200.main"
          // bgcolor={bgColor ? `${bgColor}` : "transparent"}
          alignItems="center"
          spacing={1}
          width="100%"
          height="60px"
          justifyContent="left"
        >
          <InfoOutlined />
          <Typography variant="subtitle2" fontWeight={400}>
            Tratamentos declarados pelo paciente
          </Typography>
        </Stack>
        <MedicationForm
          tabValue={tabValue}
          handleChange={handleChange}
          render={render}
          editTreatment={editTreatment}
          control={control}
          setValue={setValue}
        />
        <Stack
          width="100%"
          direction="row"
          mt={2}
          mb={2}
          justifyContent="right"
        >
          {!editTreatment ? (
            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              width="fit-content"
              height="40px"
            >
              Salvar
            </PrimaryButton>
          ) : (
            <Stack direction="row" width="100%" justifyContent="space-between">
              <SecondaryButton
                width="fit-content"
                height="40px"
                onClick={() => {
                  reset();
                  setEditTreatment(null);
                }}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                onClick={handleSubmit(onEditableTreatment)}
                width="fit-content"
                height="40px"
                loading={loadingTreatment}
                disabled={false}
              >
                Editar
              </PrimaryButton>
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack
        width="100%"
        minWidth="600px"
        overflow="auto"
        maxHeight="60vh"
        paddingRight={3}
      >
        <TratamentoHistory
          reset={reset}
          filter={tabValue}
          setEditTreatment={setEditTreatment}
          setValue={setValue}
        />
      </Stack>
    </>
  );
}
