import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useViewContext } from "../../../contexts/ViewContext";

import { IconButton, TableCell, Tooltip } from "@mui/material";
import {
  Check,
  Close,
  Description,
  Notifications,
  PlayArrow,
} from "@mui/icons-material";
import { NextAppointmentsDialog } from "./NextAppointmentsDialog";
import { useClinicalRegisterContext } from "../../../contexts/ClinicalRegisterContext";
import { VerifyResponsibleDialog } from "../../../components/schedule/VerifyResponsibleDialog";
import { GetRelatedPerson } from "../../../services/fhir/relatedperson/GetRelatedPerson";
import { oids } from "../../../configs/Settings";
import { useFhirData } from "../../../utils/useFhirData";
import { useLocationContext } from "../../../contexts/LocationContext";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useInternationalVerify } from "../../../utils/useInternationalVerify";

export function NextAppointmentsActions({ row }: any) {
  const { user } = useSessionContext();
  const navigate = useNavigate();
  const { verifyEncounter } = useClinicalRegisterContext();
  const { location } = useLocationContext();
  const { availableRoles } = useViewContext();
  const [loading, setLoading] = useState(false);
  const patientId = row?.reference?.participant?.split("-")?.[1];

  const [verifyDialog, setVerifyDialog] = useState<boolean>(false);
  const [related, setRelated] = useState<any>([]);

  async function fetchRelated() {
    const relatedResponse = await GetRelatedPerson({
      patient: `${oids.cpf}-${patientId}`,
    }).then((response) => response?.data?.entry);
    const verifyRelated = relatedResponse?.filter(
      (el: any) =>
        el?.resource?.identifier &&
        !el?.resource?.active &&
        el?.resource?.period?.start
    );
    if (verifyRelated?.length) setRelated(verifyRelated);
    else setRelated([]);
    return verifyRelated;
  }

  const [dialogCancelAppointment, setDialogCancelAppointment] =
    useState<boolean>(false);

  const locationId = useFhirData
    ?.findParticipant(row?.appointment?.participant, "Location")
    ?.reference?.split("/")?.[1];

  const practitioner = useFhirData?.findParticipant(
    row?.appointment?.participant,
    "Practitioner"
  )?.display;

  const healthcare = useFhirData?.findParticipant(
    row?.appointment?.participant,
    "Healthcare"
  )?.display;

  const locationResource = location?.find((loc: any) =>
    loc?.resource?.id.includes(locationId)
  )?.resource;

  const address = locationResource?.address?.line?.join(", ");

  const auxVirtualService = useFhirData.virtualService(
    location?.find((loc: any) => loc?.resource?.id.includes(locationId))
      ?.resource
  );

  const link = row?.appointment?.virtualService?.[0]?.addressUrl;

  const lembreteMessage = auxVirtualService
    ? `Olá ${row?.patient}. Você possui uma teleconsulta agendada para ${row?.date} - ${row?.hour} com Dr. ${practitioner} na especialidade de ${healthcare}. Para acessar a videoconferênciar por favor acesse o link: ${link}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`
    : `Olá ${row?.patient}. Você possui uma teleconsulta agendada para ${row?.date} - ${row?.hour} com Dr. ${practitioner} na especialidade de ${healthcare}. Endereço: ${address}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`;

  const startEncounter = row?.practitionerReference?.includes(user?.username);

  return (
    <>
      {dialogCancelAppointment && (
        <NextAppointmentsDialog
          setModalOpen={setDialogCancelAppointment}
          open={dialogCancelAppointment}
          row={row}
        />
      )}
      <TableCell align="center">
        <Tooltip title="Lembrete de consulta">
          <IconButton
            disabled={loading || !link}
            onClick={() =>
              window.open(
                `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                  row?.phone
                )}&text=${lembreteMessage}`,
                "_blank"
              )
            }
          >
            <Notifications />
          </IconButton>
        </Tooltip>
        {row?.status !== "cancelled" && (
          <Tooltip title="Cancelar consulta">
            <IconButton
              sx={{
                color: "error600.main",
                "&:hover": {
                  background: "error700.main",
                },
              }}
              onClick={() => setDialogCancelAppointment(true)}
            >
              <Close />
            </IconButton>
          </Tooltip>
        )}

        {(row?.status === "pending" || row?.status === "proposed") && (
          <IconButton>
            <Check />
          </IconButton>
        )}

        {(row?.status === "booked" || row?.status === "arrived") &&
          availableRoles?.includes("practitioner") && (
            <>
              {startEncounter && (
                <Tooltip title="Iniciar atendimento">
                  <IconButton
                    disabled={loading}
                    onClick={async () => {
                      const verifyRelated = await fetchRelated();

                      if (verifyRelated?.length) {
                        setVerifyDialog(true);
                      } else {
                        setLoading(true);
                        await verifyEncounter(row?.appointment);
                      }
                    }}
                  >
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Acessar registros">
                <IconButton
                  onClick={() => {
                    if (patientId)
                      navigate("/summary", {
                        state: {
                          patientId,
                          appointment: row?.appointment,
                        },
                      });
                  }}
                >
                  <Description />
                </IconButton>
              </Tooltip>
            </>
          )}

        {(row?.status === "pending" || row?.status === "proposed") && (
          <IconButton>
            <Check />
          </IconButton>
        )}
      </TableCell>

      {verifyDialog && (
        <VerifyResponsibleDialog
          handleClose={() => setVerifyDialog(false)}
          isOpen={true}
          loading={loading}
          setLoading={setLoading}
          dataAppointment={row?.appointment}
          related={related}
          fetchRelated={fetchRelated}
          patientId={patientId}
        />
      )}
    </>
  );
}
