import { Stack } from "@mui/material";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import dayjs from "dayjs";
import { VitalSignsHistoryAccordion } from "./VitalSignsHistoryAccordion";

export function VitalSignsHistory() {
  const { summaryData } = useMedicalCareContext();
  const { questionnaireDefualt } = useQuestionnaireContext();

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  // Array com todos os códigos LOINC que queremos filtrar
  const vitalSignsCodes = [
    "55284-4", // Pressão Arterial Sistólica e Diastólica
    "8480-6", // Pressão Arterial Sistólica
    "8462-4", // Pressão Arterial Diastólica
    "8310-5", // Temperatura corporal
    "9279-1", // Frequência respiratória
    "8867-4", // Frequência cardíaca
    "72514-3", // Dor (escala)
  ];

  // Array com os textos correspondentes
  const vitalSignsTexts = [
    "Pressão Arterial Sistólica e Diastólica",
    "Pressão arterial Sistólica",
    "Pressão arterial Diastólica",
    "Temperatura",
    "Frequência respiratória",
    "Frequência cardíaca",
    "Dor",
  ];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry?.filter((e: any) => {
      const verifyDate = questionnaireDefualt
        ? dayjs(questionnaireDefualt?.resource?.authored).isAfter(
            e?.resource?.effectiveDateTime
          )
        : true;

      // Verifica se o código está na lista de códigos de sinais vitais
      const isVitalSignCode =
        e?.resource?.code?.coding?.[0]?.code &&
        vitalSignsCodes.includes(e?.resource?.code?.coding?.[0]?.code);

      // Verifica se o texto corresponde a algum sinal vital
      const isVitalSignText = vitalSignsTexts.includes(e?.resource?.code?.text);

      return (isVitalSignCode || isVitalSignText) && verifyDate;
    }),
    encounterSummary
  );

  return (
    <GenericTimeline
      data={filterEncounter
        ?.filter((enc: any) =>
          questionnaireDefualt
            ? dayjs(enc?.answerDate).isAfter(
                questionnaireDefualt?.resource?.authored
              )
            : true
        )
        ?.sort((a: any, b: any) => {
          const dateA = dayjs(a?.answerDate);
          const dateB = dayjs(b?.answerDate);
          return dateB.diff(dateA);
        })}
      component={(data: any, index: number) => (
        <VitalSignsHistoryAccordion data={data} index={index} />
      )}
    />
  );
}
