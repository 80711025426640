import { Stack } from "@mui/material";
import { Control } from "react-hook-form";
import { InputText } from "../../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { scrollbarStyle } from "../../scrollbarStyle";

interface AnamneseItem {
  linkId: string;
  text: string;
}

interface AnamneseInputProps {
  items: AnamneseItem[];
  control: Control<any>;
  onFieldSelect: (linkId: string) => void;
  isMobile?: boolean;
}

export function AnamneseInput({
  items,
  control,
  onFieldSelect,
  isMobile = false,
}: AnamneseInputProps) {
  return (
    <Stack
      width={isMobile ? "100%" : "50%"}
      maxHeight={isMobile ? "40vh" : "59vh"}
      overflow="auto"
      spacing={1}
      sx={{
        ...scrollbarStyle,
      }}
    >
      {items?.map((input) => (
        <InputText
          key={input.linkId}
          onFocus={() => onFieldSelect(input.linkId)}
          minRows={3}
          maxRows={isMobile ? 6 : 100}
          multiline
          autoFocus={input.text === "Queixa principal"}
          label={input.text}
          name={`anamnese.${input.text}`}
          control={control}
        />
      ))}
    </Stack>
  );
}
