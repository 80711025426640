import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { IObservationData } from "../../../utils/interfaces";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";
import dayjs from "dayjs";

interface IDataCarePlan {
  plan: string;
  patientID: string;
  encounterID: string;
  practitionerID: string;
  practitionerName: string;
}

export async function PostCarePlan(objData: IDataCarePlan) {
  let url = `${endpoints.EHRPOSTER}/CarePlan`;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const data = JSON.stringify({
    resourceType: "CarePlan",
    meta: {
      profile: [
        "http://fluxmed.com.br/fhir/r5/StructureDefinition/FM-CarePlan",
      ],
    },
    status: "active",
    intent: "plan",
    title: "Plano de Cuidados",
    description: objData.plan,
    subject: {
      reference: `Patient/2.16.840.1.113883.13.237-${objData.patientID}`,
    },
    encounter: {
      reference: `Encounter/${objData.encounterID}`,
    },
    created: dayjs().toISOString(),
    period: {
      start: dayjs().toISOString(),
    },
    contributor: [
      // Enfermeiro
      {
        reference: `Practitioner/2.16.840.1.113883.13.237-${objData.practitionerID}`,
        display: objData.practitionerName,
      },
    ],
  });

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 20000 })
    .then((response) => {
      if (
        response.status !== HTTPResponseStatus.Created &&
        response.status !== HTTPResponseStatus.Success
      ) {
        throw new Error(`PostObservation: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PostObservation ", error);
      // toast.error(`PostObservation Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
