import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

interface AnamneseHistoryAccordionProps {
  filter: number;
  data: any;
  index: number;
  isMobile?: boolean;
}

export function AnamneseHistoryAccordion({
  filter,
  data,
  index,
  isMobile = false,
}: AnamneseHistoryAccordionProps) {
  const [expanded, setExpanded] = useState(index === 0);

  const handleChange = (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      sx={{
        width: "100%",
        minWidth: isMobile ? "100%" : "300px",
        flexGrow: 1,
        boxShadow: "1px 1px 16px 1px rgba(0, 0, 0, 0.1)",
        position: "initial",
        border: "1px solid",
        borderColor: "neutral200.main",
        borderRadius: 1,
        "& .MuiAccordionDetails-root": {
          maxHeight: "calc(100vh - 300px)",
          overflow: "auto",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Stack
          direction="row"
          width="100%"
          spacing={2}
          sx={{ overflow: "hidden" }}
        >
          <Chip
            label={data?.displayInfo?.healthcare || "Sem especialidade"}
            size="small"
            sx={{
              maxWidth: "100%",
              color: data?.displayInfo?.healthcare
                ? "neutral700.main"
                : "neutral500.main",
              fontWeight: 500,
              backgroundColor: data?.displayInfo?.healthcare ? "" : "#f1f1f1",
              "& .MuiChip-label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          {/* Queixa Principal */}
          <Stack
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "#ccc",
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{ color: "neutral600.main", mb: 1 }}
            >
              Queixa Principal
            </Typography>
            <Typography sx={{ wordBreak: "break-word" }}>
              {data?.filterResponse?.[0]?.answer?.[0]?.valueString || "-"}
            </Typography>
          </Stack>

          {/* História Atual */}
          <Stack
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "#ccc",
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{ color: "neutral600.main", mb: 1 }}
            >
              História atual
            </Typography>
            <Typography sx={{ wordBreak: "break-word" }}>
              {data?.filterResponse?.[1]?.answer?.[0]?.valueString || "-"}
            </Typography>
          </Stack>

          {/* História Patológica */}
          <Stack
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "#ccc",
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{ color: "neutral600.main", mb: 1 }}
            >
              História patológica
            </Typography>
            <Typography sx={{ wordBreak: "break-word" }}>
              {data?.filterResponse?.[2]?.answer?.[0]?.valueString || "-"}
            </Typography>
          </Stack>

          {/* História Social */}
          <Stack
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "#ccc",
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{ color: "neutral600.main", mb: 1 }}
            >
              História social
            </Typography>
            <Typography sx={{ wordBreak: "break-word" }}>
              {data?.filterResponse?.[3]?.answer?.[0]?.valueString || "-"}
            </Typography>
          </Stack>

          {/* História Familiar */}
          <Stack
            sx={{
              padding: 2,
              border: "1px solid",
              borderColor: "#ccc",
              borderRadius: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{ color: "neutral600.main", mb: 1 }}
            >
              História familiar
            </Typography>
            <Typography sx={{ wordBreak: "break-word" }}>
              {data?.filterResponse?.[4]?.answer?.[0]?.valueString || "-"}
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ my: 2 }} />
        <Typography
          color="neutral700.main"
          sx={{
            fontWeight: 500,
            fontStyle: "italic",
            fontSize: 12,
            wordBreak: "break-word",
          }}
        >
          Dr. {data?.displayInfo?.practitioner}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
