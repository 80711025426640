import { Stack } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { SocialFields } from "./SocialFields";
import { SocialHistory } from "./SocialHistory";
import { scrollbarStyle } from "../scrollbarStyle";

export function SocialHabits() {
  const { loadingResponse } = useQuestionnaireContext();
  const { patient } = useMedicalCareContext();
  return (
    <Stack width="100%" direction="row">
      <Stack width="50%">
        <SocialFields />
      </Stack>
      {!loadingResponse && (
        <Stack
          maxHeight="60vh"
          width="60%"
          overflow="auto"
          sx={{
            ...scrollbarStyle,
          }}
        >
          <SocialHistory />
        </Stack>
      )}
    </Stack>
  );
}
