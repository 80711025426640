import { toast } from "react-toastify";
import { endpoints } from "../../../../configs/Settings";
import { HTTPResponseStatus } from "../../../../utils/enum";

import { useAuth } from "../../../../utils/useAuth";
import { useAxios } from "../../../../utils/useAxios";
import { auxiliarData } from "../../AuxiliarData";
import { GetCIAP2 } from "@/services/terminology/GetCIAP2";

export async function PostCIAP2Condition(objData: any, encounter: any) {
  try {
    const verifyValueset = await GetCIAP2({
      filter: objData?.text,
    });

    // Só prossegue com o POST se encontrar uma correspondência exata
    if (verifyValueset?.data?.expansion?.contains?.length === 1) {
      const auxCiap = verifyValueset?.data?.expansion?.contains[0];
      objData.code = auxCiap?.code;
      objData.display = auxCiap?.display;

      const url = `${endpoints.EHRPOSTER}/Condition`;
      const data = auxiliarData.CIAP2Condition(objData, encounter);
      const headers = {
        Accept: "application/fhir+json",
        Authorization: await useAuth.getUserToken(),
        "Content-Type": "application/fhir+json",
      };

      const response = await useAxios
        .post(url, data, { headers, timeout: 20000 })
        .then((response) => {
          if (
            response.status !== HTTPResponseStatus.Created &&
            response.status !== HTTPResponseStatus.Success
          ) {
            throw new Error(`PostCIAP2Condition: ${response.status}`);
          }
          console.log("response", response.data);
          return response.data;
        })
        .catch((error) => {
          console.error("PostCondition ", error);
          throw new Error();
        });

      return response;
    }

    // Se não encontrar correspondência exata, retorna sem fazer o POST
    return null;
  } catch (error) {
    console.error("PostCIAP2Condition error:", error);
    throw error;
  }
}
