import { useState } from "react";

import { Button, Radio, Stack, Typography } from "@mui/material";
import { SubmitActions } from "./SubmitActions";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";

import { useViewContext } from "../../../contexts/ViewContext";
import { toast } from "react-toastify";
import { useLocationContext } from "../../../contexts/LocationContext";

export function SelectLocation({
  setLocationSelected,
  setCurrentStep,
  setValue,
  handleSubmit,
  onSubmit,
  getValues,
}: any) {
  const { location } = useLocationContext();

  const clickNext = async (data: any) => {
    await onSubmit(data);
    setCurrentStep((prev: number) => prev + 1);
  };

  const formatAddress = (addressData?: any) => {
    const auxAddress = addressData;
    const city = auxAddress?.city;
    const state = auxAddress?.state;
    const postalCode = auxAddress?.postalCode;

    return auxAddress.line
      ? auxAddress?.line?.join(", ")
      : Object.keys(auxAddress)?.length === 1
      ? "Teleconsulta"
      : `${city && city}, ${state && state}, ${postalCode && postalCode}`;
  };

  const fillAddress = (name: string, address: string) => {
    const fieldsAddress: Array<string> = [
      "name",
      "city",
      "state",
      "cep",
      "number",
      "street",
      "complement",
      "zone",
      "locationId",
    ];

    const addressFormatter = address?.split(",");

    const valuesOrganization: Array<string> = [
      name,
      addressFormatter?.[0]?.replace(" ", "") || "",
      addressFormatter?.[1]?.replace(" ", "") || "",
      addressFormatter?.[2]?.replace(" ", "") || "",
      addressFormatter?.[3]?.replace(" ", "") || "",
      addressFormatter?.[4]?.replace(" ", "") || "",
      addressFormatter?.[5]?.replace(" ", "") || "",
      addressFormatter?.[6]?.replace(" ", "") || "",
      selectedRow?.[0]?.id || "",
    ];

    fieldsAddress.map((el, index) =>
      setValue(`${el}`, valuesOrganization[index])
    );
  };

  const rows = location
    ?.filter((e: any) => e.resource?.status === "active")
    ?.map((elLocation: fhir5.BundleEntry<fhir5.Location>) => {
      const data = {
        id: elLocation?.resource?.id,
        name: elLocation?.resource?.name,
        address: elLocation?.resource?.address
          ? formatAddress(elLocation?.resource?.address)
          : "Teleconsulta",
      };
      return data;
    });
  let radioChecked = [getValues("locationId") || rows?.[0]?.id];
  const columns = [
    {
      field: "radiobutton",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params: any) => (
        <Radio checked={radioChecked[0] === params.id} value={params.id} />
      ),
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 0.6,
    },
    {
      field: "address",
      headerName: "Endereço",
      // width: 500,
      flex: 1,
      // minWidth: 150,
    },
  ];

  const [selectionModel, setSelectionModel] = useState(radioChecked);
  radioChecked = selectionModel;

  const selectedRow = rows?.filter((item: any) => {
    return item?.id === selectionModel[0];
  });

  const { availableRoles } = useViewContext();

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Button
          fullWidth
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "flex-start",
            color: "neutral1000.main",
            gap: 1,
            textTransform: "initial",
          }}
          onClick={() => {
            setLocationSelected(true);
            setValue("locationFilled", true);
            setValue("locationId", undefined);
          }}
        >
          <Add sx={{ marginLeft: 1 }} />
          Adicionar nova localização
        </Button>
        <GridFooter
          sx={{
            border: "none",
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    <Stack width="99%" spacing={2} mb={2}>
      <Typography
        variant="h6"
        fontWeight="600"
        color="neutral700.main"
        textAlign="center"
        padding="40px 0px 0px 0px"
      >
        {availableRoles?.includes("manager")
          ? "Selecione ou adicione uma localização"
          : "Selecione uma localização"}
      </Typography>

      <DataGrid
        rows={rows || []}
        columns={columns}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooterPagination
        hideFooter={!availableRoles?.includes("manager")}
        hideFooterSelectedRowCount
        localeText={{ noRowsLabel: "Você ainda não possui registros" }}
        rowSelectionModel={selectionModel}
        slots={availableRoles?.includes("manager") && { footer: CustomFooter }}
        onRowSelectionModelChange={(newSelectionModel: any) => {
          setSelectionModel(newSelectionModel);
        }}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
      />

      <SubmitActions
        goForward={() => {
          fillAddress(selectedRow?.[0]?.name || "", selectedRow?.[0]?.address);
          setValue("locationFilled", false);
          selectedRow?.[0]?.id === undefined
            ? toast.warning("Adicione uma localização")
            : handleSubmit(clickNext);
        }}
      />
    </Stack>
  );
}
