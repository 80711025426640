import { Stack } from "@mui/material";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import { AntropometricHistoryAccordion } from "./AntropometricHistoryAccordion";
import dayjs from "dayjs";
import { scrollbarStyle } from "../scrollbarStyle";

export function AnthropometricHistory() {
  const { summaryData } = useMedicalCareContext();
  const { questionnaireDefualt } = useQuestionnaireContext();

  const observationSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Observation"
  )?.[0];
  const encounterSummary = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Encounter"
  )?.[0];

  // Array com todos os códigos LOINC que queremos filtrar
  const anthropometricCodes = [
    "29463-7", // Peso (Body weight)
    "8302-2", // Altura (Body height)
    "8280-0", // Circunferência Abdominal
    "9843-4", // Perímetro Cefálico
    "8665-2", // Data da Última Menstruação
    "11996-6", // Quantidade de Gestações
    "11612-9", // Quantidade de Abortos
    "11640-0", // Partos
    "63895-7", // Tipo de Aleitamento Materno
    "11885-1", // Idade Gestacional
  ];

  const anthropometricTexts = [
    "Altura",
    "Peso",
    "Circunferência Abdominal",
    "Perímetro Cefálico",
    "Data da Última Menstruação",
    "Quantidade de Gestações",
    "Quantidade de Abortos",
    "Partos",
    "Tipo de Aleitamento Materno",
    "Idade Gestacional",
  ];

  const filterEncounter = useFhirData?.groupObservationsByEncounter(
    observationSummary?.entry?.filter((e: any) => {
      const verifyDate = questionnaireDefualt
        ? dayjs(questionnaireDefualt?.resource?.authored).isAfter(
            e?.resource?.effectiveDateTime
          )
        : true;

      // Verifica se o código está na lista de códigos antropométricos
      const isAnthropometricCode =
        e?.resource?.code?.coding?.[0]?.code &&
        anthropometricCodes.includes(e?.resource?.code?.coding?.[0]?.code);

      // Verifica se o texto corresponde a alguma medida antropométrica
      const isAnthropometricText = anthropometricTexts.includes(
        e?.resource?.code?.text
      );

      return (isAnthropometricCode || isAnthropometricText) && verifyDate;
    }),
    encounterSummary
  );

  return (
    <GenericTimeline
      data={filterEncounter
        ?.filter((enc: any) =>
          questionnaireDefualt
            ? dayjs(enc?.answerDate).isAfter(
                questionnaireDefualt?.resource?.authored
              )
            : true
        )
        ?.sort((a: any, b: any) => {
          const dateA = dayjs(a?.answerDate);
          const dateB = dayjs(b?.answerDate);
          return dateB.diff(dateA);
        })}
      component={(data: any, index: number) => (
        <AntropometricHistoryAccordion data={data} index={index} />
      )}
    />
  );
}
