import { Notifications, WhatsApp } from "@mui/icons-material";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { oids } from "../../configs/Settings";
import { useLocationContext } from "../../contexts/LocationContext";
import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { useSessionContext } from "../../contexts/SessionContext";
import { GetAppointment } from "../../services/fhir";
import { useDict } from "../../utils/useDict";
import { useFhirData } from "../../utils/useFhirData";
import { useFormatter } from "../../utils/useFormatter";
import { useInternationalVerify } from "../../utils/useInternationalVerify";
import { DetailsRow } from "./DetailsRow";
import { PatientActions } from "./PatientActions";
import { ProfessionalActions } from "./ProfessionalActions";
import ScheduleCancelDialog from "./ScheduleCancelDialog";

const stackStyle: any = {
  borderColor: {
    booked: "primary600.main",
    cancelled: "error600.main",
    proposed: "neutral600.main",
    pending: "neutral600.main",
  },
  backgroundColor: {
    booked: "primary100.main",
    cancelled: "error100.main",
    proposed: "neutral100.main",
    pending: "neutral100.main",
  },
};

interface IProps {
  data: (fhir5.Appointment | fhir5.Location | undefined)[];
  setRefetch: any;
  patient: fhir5.BundleEntry<fhir5.Patient> | undefined;
}

export function AppointmentDetails({ data, setRefetch, patient }: IProps) {
  const { access, user } = useSessionContext();
  const { location: locationContext } = useLocationContext();
  const { organizationList } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lastAppointment, setLastAppointment] = useState<string>();
  const { formatDateTimeToHour } = useFormatter;

  const contact = patient?.resource?.telecom;
  const locationAppointment = locationContext?.find(
    (location) => location?.resource?.id === data[1]?.id
  );

  const locationContact =
    locationAppointment?.resource?.contact?.[0]?.telecom?.[0]?.value;
  const referenceOrganization =
    locationAppointment?.resource?.managingOrganization?.reference?.split(
      "/"
    )?.[1];

  const organizationContact = organizationList?.find(
    (organization: any) => organization?.resource?.id === referenceOrganization
  )?.resource?.contact?.[0]?.telecom?.[0]?.value;

  const phone = contact?.find((e: any) => e.system === "phone")?.value;

  const appointment = data.find(
    (el) => el?.resourceType === "Appointment"
  ) as fhir5.Appointment;
  const location = data.find(
    (el) => el?.resourceType === "Location"
  ) as fhir5.Location;

  const locationTeleconsulting = useFhirData.virtualService(
    locationContext?.find((loc: any) =>
      loc?.resource?.id.includes(location?.id)
    )?.resource
  );

  function formatAddress(location: any) {
    return (
      location?.line?.join(", ") ||
      [location?.city, location?.state, location?.postalCode]
        .filter((el) => el)
        .join(", ") ||
      location?.country ||
      undefined
    );
  }
  const address = formatAddress(
    locationContext?.find((loc: any) =>
      loc?.resource?.id.includes(location?.id)
    )?.resource?.address || ""
  );

  const auxDataAppointment = {
    id: appointment?.id,
    participant: useFhirData.findParticipant(
      appointment?.participant,
      "Patient"
    ),
  };

  const appointmentContent = {
    status: appointment?.status,
    start: appointment?.start || "-",
    patient: patient?.resource?.name?.[0]?.text || "-",
    phone,
    practitioner: useFhirData.findParticipant(
      appointment?.participant,
      "Practitioner"
    )?.display,

    location: useFhirData.findParticipant(appointment?.participant, "Location")
      ?.display,

    healthcare: useFhirData.findParticipant(
      appointment?.participant,
      "HealthcareService"
    )?.display,

    address: formatAddress(location),

    appointmentType: appointment?.appointmentType?.text
      ? useDict.appointmentType[appointment?.appointmentType?.text]
      : "-",
    contact: locationContact || organizationContact || "-",
  };

  const minFieldWidth = "100px";
  const { formatNameDisplay } = useFormatter;

  const patientCPF = auxDataAppointment?.participant?.reference?.split("-")[1];

  useEffect(() => {
    setLoading(true);
    setLastAppointment("-");
    async function lastEncounter() {
      try {
        const response = await GetAppointment({
          actor: `Patient/${oids.cpf}-${patientCPF}`,
          professionalActor: `Practitioner/${oids.cpf}-${user.username}`,
          organization: access.organizationId,
          sort: "-_lastUpdated",
          count: 1,
          scheduling: true,
          status: ["fulfilled"],
        });
        const value =
          response?.entry &&
          dayjs(response?.entry?.[0].resource?.meta.lastUpdated).format(
            "DD/MM/YYYY"
          );

        const typeAppointment =
          useDict.appointmentType[
            response.entry?.[0]?.resource?.appointmentType?.text
          ];

        const healthcareService = useFhirData.findParticipant(
          response.entry?.[0].resource?.participant,
          "HealthcareService"
        )?.display;

        const info = [value, typeAppointment, healthcareService]
          ?.filter((el: any) => el)
          ?.join(" - ");

        setLastAppointment(info);
      } catch (err) {
        console.log("e", err);
      } finally {
        setLoading(false);
      }
    }

    if (appointment) lastEncounter();
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  const link = appointment?.virtualService?.[0]?.addressUrl;
  const hour = appointment?.start && formatDateTimeToHour(appointment?.start);
  const date = dayjs(appointment?.start)?.format("DD/MM/YYYY ") || "-";

  const lembreteMessage = locationTeleconsulting
    ? `Olá ${patient?.resource?.name?.[0]?.text}. Você possui uma teleconsulta agendada para ${date}-${hour} com Dr. ${appointmentContent?.practitioner} na especialidade de ${appointmentContent?.healthcare}. Para acessar a videoconferênciar por favor acesse o link: ${link}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`
    : `Olá ${patient?.resource?.name?.[0]?.text}. Você possui uma consulta agendada para ${date}-${hour} com Dr. ${appointmentContent?.practitioner} na especialidade de ${appointmentContent?.healthcare}. Endereço: ${address}. Acesse https://app.fluxmed.com.br, e faça seu cadastro para acessar seus registros eletrônicos de saúde, agendar consultas e muito mais.`;

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderRadius: "10px",
        borderColor: `${
          stackStyle.borderColor[appointmentContent.status || "pending"]
        }`,
        backgroundColor: `${
          stackStyle.backgroundColor[appointmentContent.status || "pending"]
        }`,
      }}
      spacing={2}
      width={{ xs: "100%" }}
      height="fit-content"
      justifyContent="space-between"
    >
      <Stack padding="24px 32px">
        <Stack
          spacing={2}
          height={access.type === "professional" ? "340px" : "260px"}
        >
          <Typography variant="h6" fontWeight="600">
            {access.type === "patient"
              ? " Detalhes da consulta"
              : "Próximo paciente"}
          </Typography>

          {access.type === "patient" ? (
            <>
              <DetailsRow
                field="Médico"
                value={appointmentContent.practitioner || "-"}
                minWidth={minFieldWidth}
              />
              <DetailsRow
                field={locationTeleconsulting ? "Tipo" : "Local"}
                value={locationTeleconsulting ? "Teleconsulta" : address}
                minWidth={minFieldWidth}
              />
              <DetailsRow
                field="Contato"
                value={appointmentContent.contact || "-"}
                minWidth={minFieldWidth}
              />
            </>
          ) : (
            <>
              <DetailsRow
                field="Paciente"
                value={formatNameDisplay(appointmentContent.patient)}
                minWidth={minFieldWidth}
              />

              <Stack direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  color="neutral700.main"
                  minWidth={minFieldWidth || "20%"}
                  textAlign="left"
                  sx={{ wordBreak: "break-word" }}
                  marginRight={1}
                >
                  Telefone
                </Typography>
                {phone ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                  >
                    <Typography mt={0.5} paddingRight={1}>
                      {useFormatter.formatPhoneNumber(phone || "")}
                    </Typography>
                    <Tooltip
                      title={useFormatter.formatPhoneNumber(phone || "")}
                    >
                      <IconButton
                        color="primary"
                        onClick={() =>
                          window.open(
                            `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                              phone
                            )}`,
                            "_blank"
                          )
                        }
                      >
                        <WhatsApp />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Lembrete de consulta">
                      <IconButton
                        disabled={loading || !link}
                        onClick={() =>
                          window.open(
                            `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                              phone
                            )}&text=${lembreteMessage}`,
                            "_blank"
                          )
                        }
                      >
                        <Notifications color="primary" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  "-"
                )}
              </Stack>

              <DetailsRow
                field="Servico"
                value={appointmentContent.healthcare}
                minWidth={minFieldWidth}
              />
              <DetailsRow
                field="Clínica"
                value={appointmentContent.location}
                minWidth={minFieldWidth}
              />
              <DetailsRow
                field={locationTeleconsulting ? "Tipo" : "Local"}
                value={locationTeleconsulting ? "Teleconsulta" : address}
                minWidth={minFieldWidth}
              />
            </>
          )}
          <DetailsRow
            field="Atendimento"
            value={appointmentContent.appointmentType}
            minWidth={minFieldWidth}
          />

          <Divider
            sx={{
              backgroundColor: "neutral500.main",
              height: "2px",
            }}
          />

          <DetailsRow
            field="Ultima visita"
            loading={loading}
            value={lastAppointment || "-"}
            minWidth={minFieldWidth}
          />
        </Stack>
        <Stack spacing={1}>
          {data[0] &&
            access.type === "patient" &&
            appointmentContent.status !== "cancelled" && (
              <PatientActions
                setIsOpen={setIsOpen}
                status={appointmentContent.status}
              />
            )}

          {data[0] &&
            access.type === "professional" &&
            appointmentContent.status !== "cancelled" && (
              <ProfessionalActions
                setIsOpen={setIsOpen}
                status={appointmentContent.status}
                dataAppointment={appointment}
              />
            )}
        </Stack>
      </Stack>

      <ScheduleCancelDialog
        appointment={appointment}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRefetch={setRefetch}
      />
    </Stack>
  );
}
