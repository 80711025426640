import { Stack } from "@mui/material";
import { scrollbarStyle } from "../scrollbarStyle";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%", ...scrollbarStyle }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack
          spacing={3}
          sx={{ pl: 3, width: "100%" }}
          direction="row"
          justifyContent="space-between"
        >
          {children}
        </Stack>
      )}
    </div>
  );
}
