import { Add } from "@mui/icons-material";
import { Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { InputText } from "../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { useMedicalCareContext } from "../../../contexts/MedicalCareContext";
import { useWatch } from "react-hook-form";
import { FormAutoComplete } from "../../../components/form";
import { GetCIAP2 } from "@/services/terminology/GetCIAP2";

export function AddNewValue({ lista, setLista, item }: any) {
  const { control, unregister, setValue } = useMedicalCareContext();
  const [render, setRender] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const inputValue = useWatch({
    name: `New_${item?.[0]?.text}.${lista?.length}`,
    control,
  });

  const isProblema = item?.[0]?.text === "Problema";

  const [searchProblems, setSearchProblems] = useState<any>();
  const [ProblemsOptions, setProblemsOptions] = useState<any>();

  // Mapeia as opções de vacinas do ValueSet ou busca direta
  const ProblemsList =
    ProblemsOptions?.expansion?.contains?.map((el: any) => ({
      ...el,
      label: `${el?.display} - ${el?.code}`,
      value: el?.code,
    })) || [];

  useEffect(() => {
    async function fetchProblems() {
      const valueSetResponse = await GetCIAP2({
        filter: searchProblems,
      });
      setProblemsOptions(valueSetResponse?.data);

      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchProblems();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchProblems]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isProblema) {
        const problemValue =
          inputValue?.display || inputValue?.value || searchProblems;
        if (problemValue) {
          setLista([...lista, problemValue]);
          setValue(`New_${item?.[0]?.text}.${lista?.length}`, null);
          setSearchProblems("");
          setRender(false);
        }
      } else {
        setLista([...lista, inputValue]);
        setRender(false);
      }
    }
  };

  useEffect(() => {
    setRender(true);
  }, [render]);

  return (
    <>
      {lista?.length > 0 && (
        <Stack
          direction="row"
          flexWrap="wrap"
          minHeight="fit-content"
          spacing={1}
          paddingBottom={1}
          gap={1}
        >
          {lista?.map((el: any, index: number) => (
            <>
              {el?.length > 0 && (
                <Chip
                  sx={{
                    padding: "8px",
                    height: "auto",
                  }}
                  key={index}
                  label={
                    el?.length > 85 ? (
                      <Tooltip
                        placement="right"
                        title={el}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [80, 20],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <Typography>{el.substring(0, 85)}...</Typography>
                      </Tooltip>
                    ) : (
                      <Typography>{el}</Typography>
                    )
                  }
                  onDelete={() => {
                    setLista((prevItems: any) => {
                      const updatedItems = [...prevItems];
                      updatedItems[index] = "";
                      return updatedItems;
                    });
                    unregister(`New_${item?.[0]?.text}.${index}`);
                    if (!isProblema) {
                      setRender(false);
                    }
                  }}
                />
              )}
            </>
          ))}
        </Stack>
      )}

      <Stack direction="row" spacing={1}>
        {render &&
          (isProblema ? (
            <FormAutoComplete
              freeSolo
              loading={loading}
              name={`New_${item?.[0]?.text}.${lista?.length}`}
              label="Problema"
              control={control}
              onInputChange={(e: any) => {
                if (e?.target?.value?.length) {
                  setSearchProblems(e?.target?.value);
                  setValue(`freeSoloProblem`, e?.target?.value);
                } else {
                  setSearchProblems("");
                  setValue(`New_${item?.[0]?.text}.${lista?.length}`, "");
                }
              }}
              onKeyDown={handleKeyDown}
              selectOptions={ProblemsList}
              autoFocus
            />
          ) : (
            <InputText
              key={lista?.length}
              label={item?.[0]?.text}
              name={`New_${item?.[0]?.text}.${lista?.length}`}
              control={control}
              sx={{ mb: 4 }}
              onKeyDown={handleKeyDown}
              autoFocus
              multiline={true}
            />
          ))}
      </Stack>

      <Stack direction="row" width="100%" justifyContent="right">
        <Button
          sx={{ width: "fit-content" }}
          onClick={() => {
            if (isProblema) {
              const problemValue =
                inputValue?.display || inputValue?.value || searchProblems;
              if (problemValue) {
                setLista([...lista, problemValue]);
                setValue(`New_${item?.[0]?.text}.${lista?.length}`, null);
                setSearchProblems("");
              }
            } else {
              setLista([...lista, inputValue]);
            }
            setRender(false);
          }}
          variant="text"
        >
          <Add />
          Adicionar
        </Button>
      </Stack>
    </>
  );
}
