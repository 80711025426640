/**
 * @description Componente de autocompletar customizado que integra Material-UI Autocomplete com React Hook Form
 * @module FormAutoComplete
 * @since Fevereiro 2024
 *
 * Funcionalidades:
 * - Integração com React Hook Form para gerenciamento de formulários
 * - Suporte a validação e estados de erro
 * - Customização de opções e renderização
 * - Suporte a modo freeSolo para entrada livre
 */

import { Controller } from "react-hook-form";
import { FormInputProps } from "../types";
import { Autocomplete, TextField } from "@mui/material";

/**
 * @interface IProps
 * @description Props específicas do componente FormAutoComplete
 *
 * @property {Array} selectOptions - Lista de opções para o autocompletar
 * @property {Object} defaultValue - Valor inicial selecionado
 * @property {boolean} loading - Indica se está carregando opções
 * @property {any} noOptionsText - Texto quando não há opções
 * @property {Function} auxOnChange - Função auxiliar executada após onChange
 * @property {Function} onInputChange - Função chamada quando o input é alterado
 * @property {boolean} freeSolo - Permite valores fora das opções
 * @property {boolean} autoFocus - Foca o campo automaticamente
 * @property {Function} onKeyDown - Função chamada quando uma tecla é pressionada
 */
interface IProps {
  selectOptions: {
    value: string | number;
    label: string;
  }[];
  defaultValue?: {
    value: string | number;
    label: string;
  };
  loading?: boolean;
  noOptionsText?: any;
  auxOnChange?: Function;
  onInputChange?: (event: React.SyntheticEvent, value: string) => void;
  freeSolo?: boolean;
  autoFocus?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}

/**
 * @type TypeMergedInterfaces
 * @description União das props do formulário com as props específicas do autocomplete
 */
type TypeMergedInterfaces = FormInputProps & IProps;

/**
 * @component FormAutoComplete
 * @description Componente de autocompletar com integração ao React Hook Form
 *
 * @example
 * // Uso básico
 * <FormAutoComplete
 *   name="campo"
 *   control={control}
 *   label="Selecione uma opção"
 *   selectOptions={[
 *     { value: '1', label: 'Opção 1' },
 *     { value: '2', label: 'Opção 2' }
 *   ]}
 * />
 */
export function FormAutoComplete({
  name,
  control,
  label,
  required,
  selectOptions,
  defaultValue,
  loading,
  noOptionsText,
  auxOnChange,
  renderOption,
  freeSolo,
  onInputChange,
  autoFocus,
  onKeyDown,
}: TypeMergedInterfaces) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Autocomplete
            sx={{
              "& .MuiFilledInput-root": { backgroundColor: "neutral100.main" },
            }}
            loading={loading}
            onInputChange={onInputChange}
            freeSolo={freeSolo}
            disablePortal
            value={value || null}
            onChange={(_, data) => {
              onChange(data || null);
              if (typeof auxOnChange === "function") {
                auxOnChange(data || null);
              }
            }}
            options={selectOptions}
            getOptionLabel={(option) => {
              if (!option) return "";
              if (typeof option === "string") return "";
              return option?.label || "";
            }}
            isOptionEqualToValue={(option, value) => {
              if (!option || !value) return true;
              return option.value === value.value;
            }}
            fullWidth
            noOptionsText={noOptionsText}
            renderInput={(params) => (
              <TextField
                variant="filled"
                error={error !== undefined}
                {...params}
                label={label}
                autoFocus={autoFocus}
              />
            )}
            renderOption={
              renderOption
                ? (props, option) => (
                    <li {...props} style={{ paddingLeft: 0 }}>
                      {renderOption(option)}
                    </li>
                  )
                : undefined
            }
            onKeyDown={onKeyDown}
          />
        );
      }}
    />
  );
}
