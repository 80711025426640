import { Grid } from "@mui/material";
import {
  FormAutoComplete,
  FormDateInput,
} from "../../../../../components/form";
import { InputText } from "../../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { useEffect, useState } from "react";
import { GetValueSet } from "../../../../../services/terminology";
import { GetCodeSystemLookup } from "../../../../../services/terminology/GetCodeSystemLookup";
import { GetImunizations } from "@/services/terminology/GetImunizations";
import { useMedicalCareContext } from "@/contexts/MedicalCareContext";
import dayjs from "dayjs";
export function ImmunizationForm({
  setValue,
  control,
  editImmunization,
  reset,
}: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [vaccineOptions, setVaccineOptions] = useState<any>();
  const [searchVaccine, setSearchVaccine] = useState<any>();
  const [searchDirectVaccine, setSearchDirectVaccine] = useState<any>();

  // Mapeia as opções de vacinas do ValueSet ou busca direta
  const vaccineList =
    searchDirectVaccine ||
    vaccineOptions?.expansion?.contains?.map((el: any) => ({
      ...el,
      label: `${el?.display} - ${el?.code}`,
      value: el?.code,
    })) ||
    [];

  useEffect(() => {
    async function fetchVaccines() {
      setSearchDirectVaccine(undefined);
      const valueSetResponse = await GetImunizations({
        filter: searchVaccine,
      });
      setVaccineOptions(valueSetResponse?.data);

      // Tenta busca direta se não encontrar no ValueSet
      if (
        searchVaccine?.length < 7 &&
        !valueSetResponse?.data?.expansion?.contains
      ) {
        const codeSystemResponse = await GetCodeSystemLookup({
          codeSystem: "https://fluxmed.com.br/fhir/r4/CodeSystem/vaccine-codes", // Ajuste para o sistema correto
          filter: searchVaccine?.toUpperCase(),
        });
        const auxDisplay = codeSystemResponse?.parameter?.find(
          (e: any) => e.name === "display"
        )?.valueString;

        if (auxDisplay) {
          setSearchDirectVaccine([
            {
              code: searchVaccine?.toUpperCase(),
              label: `${searchVaccine?.toUpperCase()} - ${auxDisplay}`,
              name: auxDisplay,
              display: auxDisplay,
              value: searchVaccine?.toUpperCase(),
            },
          ]);
        }
      }

      setLoading(false);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchVaccines();
    }, 500);
    return () => clearTimeout(debounce);
  }, [searchVaccine]);

  useEffect(() => {
    if (editImmunization) {
      setValue("immunization.narrative", editImmunization?.narrative);
      setValue("immunization.vaccine", {
        display: editImmunization?.description,
        code: editImmunization?.fullResource?.vaccineCode?.coding?.[0]?.code,
        system:
          editImmunization?.fullResource?.vaccineCode?.coding?.[0]?.system,
        label: `${editImmunization?.description} - ${editImmunization?.fullResource?.vaccineCode?.coding?.[0]?.code}`,
      });
      setValue("immunization.date", editImmunization?.date);
    }
  }, [editImmunization, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <FormAutoComplete
          required
          freeSolo
          loading={loading}
          name="immunization.vaccine"
          label="Vacina"
          control={control}
          onInputChange={(e: any) => {
            if (e?.target?.value?.length) {
              setValue("immunization.vaccine", {
                display: e?.target.value,
                name: e?.target?.value,
                label: e?.target?.value,
                value: e?.target?.value,
              });
              setSearchVaccine(e?.target?.value);
            } else {
              setSearchVaccine("");
              setValue("immunization.vaccine", null);
            }
          }}
          selectOptions={vaccineList}
          autoFocus
        />
      </Grid>

      <Grid item xs={3}>
        <FormDateInput
          label="Data de administração"
          name="immunization.date"
          control={control}
          defaultValue={dayjs()}
        />
      </Grid>

      <Grid item xs={12}>
        <InputText
          name="immunization.narrative"
          label="Narrativa de vacinação"
          control={control}
          multiline
          rows={3}
          defaultValue=""
        />
      </Grid>
    </Grid>
  );
}
