import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { AnamneseInput } from "./components/AnamneseInput";
import { AnamneseHistoryView } from "./components/AnamneseHistoryView";

export function Anamnese() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { questionnaire, loadingResponse } = useQuestionnaireContext();
  const { control } = useMedicalCareContext();

  // Encontra os itens de anamnese
  const anamneseItems =
    questionnaire?.item?.find((el: any) => el.text === "Anamnese")?.item || [];

  // Estado para campo selecionado
  const [selectedField, setSelectedField] = useState<string>(
    questionnaire?.item?.[0]?.item?.[0]?.linkId || ""
  );

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      spacing={2}
      width="100%"
      sx={{
        minHeight: isMobile ? "auto" : "59vh",
        padding: 1,
      }}
    >
      {/* Componente de entrada de dados */}
      <AnamneseInput
        items={anamneseItems}
        control={control}
        onFieldSelect={setSelectedField}
        isMobile={isMobile}
      />

      {/* Componente de visualização do histórico */}
      {!loadingResponse && (
        <AnamneseHistoryView
          selectedField={selectedField}
          isMobile={isMobile}
        />
      )}
    </Stack>
  );
}
