/* eslint-disable @typescript-eslint/no-unused-vars */
import { VideoCameraFront } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Draggable from "react-draggable";
import { useSessionContext } from "../../contexts/SessionContext";
import JitsiComponent from "./JitsiComponent";
import WherebyComponent from "./WherebyComponent";

interface VideoConferenciaProps {
  roomUrl?: string;
  cardSchedule?: boolean;
  appointmentData?: {
    id: string;
    patientName: string;
    practitionerName: string;
    startTime: string;
  };
}

const VideoConferencia: React.FC<VideoConferenciaProps> = ({
  roomUrl,
  cardSchedule,
  appointmentData,
}) => {
  const { access, user } = useSessionContext();
  const [startMeeting, setStartMeeting] = useState(false);
  const [showJitsi, setShowJitsi] = useState(false);
  const [screenSize, setScreenSize] = useState(
    access?.type === "patient" ? 2 : 1
  );
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [jitsiPosition, setJitsiPosition] = useState({ x: 0, y: 0 });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  // Função para calcular posição central
  const calculateCenterPosition = (size: number) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    return {
      x: (windowWidth - 400 * size) / 2,
      y: (windowHeight - 300 * size) / 2,
    };
  };

  useEffect(() => {
    const initialPosition = calculateCenterPosition(screenSize);
    setPosition(initialPosition);
    setJitsiPosition(initialPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Executar apenas na montagem

  const handleStartMeeting = () => {
    if (roomUrl) {
      if (isMobile) {
        window.open(roomUrl);
      } else {
        setStartMeeting(true);
      }
    } else {
      setShowJitsi(true);
    }
  };

  const handleCloseMeeting = () => {
    setStartMeeting(false);
    setShowJitsi(false);
  };

  const handleResize = (size: number) => {
    // Calcula nova posição mantendo o centro
    const newPosition = calculateCenterPosition(size);
    setPosition(newPosition);
    setJitsiPosition(newPosition);
    setScreenSize(size);
  };

  const generateJitsiRoomName = () => {
    if (!appointmentData) return `medical-appointment-${Date.now()}`;
    return `appointment-${appointmentData.id}`;
  };

  const renderButton = () => {
    const buttonStyles: SxProps<Theme> = cardSchedule
      ? {
          ...(isMobile
            ? {
                // Estilo mobile - botão verde
                width: "100%",
                backgroundColor: "primary700.main",
                color: "neutral0.main",
                textTransform: "none",
                gap: 1,
                borderRadius: 2,
                py: 1,
                "&:hover": {
                  backgroundColor: "primary800.main",
                },
              }
            : {
                // Estilo web - apenas ícone preto
                minWidth: "auto",
                padding: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }),
        }
      : {
          // Botão de acessar teleconsulta (full width)
          width: "100%",
          backgroundColor: "primary700.main",
          color: "neutral0.main",
          textTransform: "none",
          gap: 1,
          borderRadius: 2,
          py: 1,
          "&:hover": {
            backgroundColor: "primary800.main",
          },
        };

    const icon = (
      <VideoCameraFront
        sx={{
          color: cardSchedule
            ? isMobile
              ? "neutral0.main"
              : "neutral900.main"
            : "neutral0.main",
          fontSize: 24,
        }}
      />
    );

    const buttonContent = cardSchedule ? (
      isMobile ? (
        // Versão mobile - botão verde com texto
        <>
          {icon}
          <Typography variant="body2" color="neutral0.main">
            Acessar Teleconsulta
          </Typography>
        </>
      ) : (
        // Versão web - apenas ícone preto
        icon
      )
    ) : (
      // Botão de acessar teleconsulta abaixo dos cards
      <>
        {icon}
        <Typography variant="body2" color="neutral0.main">
          {access?.type === "patient"
            ? "Acessar Teleconsulta"
            : "Iniciar Teleatendimento"}
        </Typography>
      </>
    );

    return (
      <Button
        variant={cardSchedule && !isMobile ? "text" : "contained"}
        onClick={handleStartMeeting}
        sx={buttonStyles}
      >
        {buttonContent}
      </Button>
    );
  };

  const renderVideoWindow = (isJitsi: boolean) => {
    const currentPosition = isJitsi ? jitsiPosition : position;

    if (isMobile) {
      return (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "90%",
              backgroundColor: "background.paper",
              borderRadius: 2,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              boxShadow: 24,
              height: "40vh", // Reduzido de 45vh para 40vh
            }}
          >
            {/* Header do Modal */}
            <Box
              sx={{
                bgcolor: "grey.100",
                p: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: 1,
                borderColor: "divider",
                height: "36px", // Reduzido de 40px para 36px
              }}
            >
              <Typography variant="subtitle1">
                {isJitsi ? "Teleconsulta" : "Sala de Atendimento"}
              </Typography>
              <IconButton
                onClick={handleCloseMeeting}
                size="small"
                sx={{ color: "error.main" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Conteúdo do Modal */}
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "calc(40vh - 36px)", // Ajustado para as novas medidas
              }}
            >
              <WherebyComponent
                roomUrl={roomUrl || ""}
                screenSize={1}
                onClose={handleCloseMeeting}
              />
            </Box>
          </Box>
        </Box>
      );
    }

    // Versão desktop
    const bounds = {
      left: 10,
      top: 10,
      right: window.innerWidth - 400 * screenSize - 10,
      bottom: window.innerHeight - 300 * screenSize - 10,
    };

    return (
      <Draggable
        handle=".modal-header"
        position={currentPosition}
        onStop={(e, data) => {
          if (isJitsi) {
            setJitsiPosition({ x: data.x, y: data.y });
          } else {
            setPosition({ x: data.x, y: data.y });
          }
        }}
        bounds={bounds}
        defaultClassName="draggable-window"
      >
        <Box
          sx={{
            position: "fixed",
            top: isMobile ? -40 : 0,
            left: isMobile ? -75 : 0,
            zIndex: 99999,
            width: `${400 * screenSize}px`,
            height: `${300 * screenSize}px`,
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 3,
          }}
        >
          <Box
            className="modal-header"
            sx={{
              cursor: "move",
              bgcolor: "grey.100",
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              {[1, 2, 3].map(
                (size) =>
                  (!isMobile || size === 1) && (
                    <Button
                      key={size}
                      variant={screenSize === size ? "contained" : "outlined"}
                      size="small"
                      onClick={() => handleResize(size)}
                      sx={{ minWidth: 40 }}
                    >
                      {size}x
                    </Button>
                  )
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton size="small">
                <DragHandleIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={handleCloseMeeting}
                sx={{ color: "error.main" }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
          <Box
            sx={{
              flex: 1,
              position: "relative",
            }}
          >
            <WherebyComponent
              roomUrl={roomUrl || ""}
              screenSize={screenSize}
              onClose={handleCloseMeeting}
            />
          </Box>
        </Box>
      </Draggable>
    );
  };

  return (
    <Container sx={{ padding: "0 !important" }}>
      {renderButton()}
      {startMeeting && roomUrl && renderVideoWindow(false)}
      {showJitsi && renderVideoWindow(true)}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default VideoConferencia;
