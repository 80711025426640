import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useFormatter } from "../../../../utils/useFormatter";
import { SecondaryButton } from "../../../../components/common";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import dayjs from "dayjs";

const styleStatus: any = {
  completed: "#007C6D",
  "entered-in-error": "#EF4352",
  "not-done": "#FBBB3C",
};

export function ImmunizationHistoryAccordion({ data, index }: any) {
  const { loadingSummary } = useMedicalCareContext();

  if (loadingSummary && index === 0) {
    return (
      <Box
        sx={{
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: 1,
          padding: 2,
        }}
      >
        <Skeleton variant="text" width={"100%"} sx={{ fontSize: "1rem" }} />
        <Stack direction="row" spacing={12} sx={{ my: 2 }}>
          <Skeleton variant="rounded" width={"100%"} height={32} />
          <Skeleton variant="rounded" width={"100%"} height={32} />
        </Stack>

        <Divider />

        <Stack direction="row" spacing={2} mt={2}>
          <Skeleton variant="text" width={"75%"} sx={{ fontSize: "1.2rem" }} />
          <Skeleton variant="text" width={"25%"} sx={{ fontSize: "1rem" }} />
        </Stack>
      </Box>
    );
  }

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      sx={{
        border: "1px solid #ccc",
        boxShadow: "none",
        borderRadius: 1,
      }}
    >
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Stack
          direction="row"
          sx={{ alignItems: "center", width: "100%" }}
          justifyContent="space-between"
        >
          <Stack>
            <Typography fontWeight={500} color="neutral700.main">
              {data.description} {data?.code ? `- ${data.code}` : ""}
            </Typography>
          </Stack>

          <Chip
            label={dayjs(data?.date).format("DD/MM/YYYY")}
            size="small"
            variant="outlined"
            sx={{
              color: styleStatus[data?.status],
              borderColor: styleStatus[data?.status],
            }}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          {data?.narrative && (
            <Stack>
              <Typography
                style={{
                  backgroundColor: "#eeeeee",
                  color: "#757575",
                  padding: 6,
                  borderRadius: 8,
                  wordBreak: "break-word",
                }}
              >
                {data.narrative}
              </Typography>
            </Stack>
          )}

          <Divider />

          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            {data?.practitioner && (
              <Typography
                color="neutral700.main"
                sx={{ fontWeight: 500, fontStyle: "italic", fontSize: 12 }}
              >
                {useFormatter.formatNameDisplay(data.practitioner)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
