import { Publish } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import { Button, Stack, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import {
  FormAutoComplete,
  FormTextInput,
} from "../../../../../../../../components/form";
import { GetMedication } from "../../../../../../../../services/terminology";
import { CustomMedicationOption } from "../../../../../prescription/CustomMedicationOption";

type SelectedTabType = "1" | "2" | "3" | "4";

interface IProps {
  control: any;
  setValue: any;
}

const unit = [
  {
    value: "GRAN",
    label: "Grânulos",
    inputType: "number",
  },
  {
    value: "COM",
    label: "Comprimido",
    inputType: "number",
  },
  {
    value: "CAP",
    label: "Cápsula",
    inputType: "number",
  },
  {
    value: "SOL OR",
    label: "Solução Oral",
  },
  {
    value: "SOL INJ",
    label: "Solução Injetável",
  },
  {
    value: "SOL",
    label: "Solução",
  },

  {
    value: "SUSP",
    label: "Suspensão",
  },
  {
    value: "POM",
    label: "Pomada",
  },
  {
    value: "GEL",
    label: "Gel",
  },
  {
    value: "XAR",
    label: "Xarope",
  },
  {
    value: "INJ",
    label: "Injetável",
  },
  {
    value: "COL",
    label: "Colírio",
  },
  {
    value: "AER",
    label: "Aerossol",
  },
  {
    value: "SUP",
    label: "Supositório",
    inputType: "number",
  },
  {
    value: "AMP",
    label: "Ampola",
    inputType: "number",
  },
  {
    value: "CTG",
    label: "Cartucho",
    inputType: "number",
  },
  {
    value: "FLD",
    label: "Fluido",
  },
  {
    value: "CJ  ",
    label: "Conjunto",
    inputType: "number",
  },
  {
    value: "ENV",
    label: "Envelope ou Sachê",
  },
  {
    value: "BISN",
    label: "Bisnaga",
    inputType: "number",
  },
  {
    value: "DISP",
    label: "Dispositivo",
  },
  {
    value: "BALA",
    label: "Bala",
    inputType: "number",
  },
  {
    value: "LAQ",
    label: "Laca",
  },
];

const frequency = [
  { value: 3, label: "3h" },
  { value: 6, label: "6h" },
  { value: 8, label: "8h" },
  { value: 12, label: "12h" },
  { value: 24, label: "24h" },
];

const time = [
  { value: 1, label: "1 dia" },
  { value: 2, label: "2 dias" },
  { value: 3, label: "3 dias" },
  { value: 5, label: "5 dias" },
  { value: 7, label: "7 dias" },
  { value: 15, label: "15 dias" },
];

export function MedicationTypeTabs({ control, setValue }: IProps) {
  const [selectedTab, setSelectedTab] = useState<SelectedTabType>("1");
  const handleChangeTab = (e: React.SyntheticEvent, value: SelectedTabType) =>
    setSelectedTab(value);

  let selectedMedication = useWatch({ name: "medication", control });
  let selectedForm = useWatch({ name: "form", control });

  const [customMedication, setCustomMedication] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [medication, setMedication] = useState([]);

  const medicationOptions =
    medication?.map((el: any, index: number) => {
      const item = {
        class:
          el?.extension?.find((ext: any) =>
            ext.url?.includes("ClasseTerapeutica")
          )?.valueString || "-",
        type:
          el?.extension?.find((ext: any) => ext.url?.includes("TipoProduto"))
            ?.valueString || "-",
        description:
          el?.extension?.find((ext: any) => ext.ur?.includes("Substancia"))
            ?.valueString || "-",
        presentation:
          el?.extension?.find((ext: any) => ext.url?.includes("Apresentacao"))
            ?.valueString || "-",
        tag:
          el?.extension?.find((ext: any) => ext.url?.includes("Tarja"))
            ?.valueString || "-",
        name:
          el?.extension?.find((ext: any) => ext.url?.includes("Produto"))
            ?.valueString || "-",
        value: index,
        code: el?.code,
      };
      return { ...item, label: `${item?.name} - ${item.presentation}` };
    }) || [];

  function verityInputType() {
    const option = unit.find((el: any) => el.value === selectedForm?.value);
    return option?.inputType || "text";
  }

  function extrairNumero(string: string) {
    const match = string?.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  useEffect(() => {
    async function fetchMedication() {
      const medicationResponse = await GetMedication(
        selectedTab,
        selectedMedication?.name
      );
      setMedication(
        medicationResponse?.data?.entry?.map((el: any) => el.resource)
      );
      setLoading(false);
    }

    if (selectedMedication?.code) setCustomMedication(false);

    const presentationForm = unit?.find((el: any) =>
      selectedMedication?.label?.includes(el.value)
    );
    if (presentationForm && !customMedication) {
      setValue("form", presentationForm);
    }

    const debounce = setTimeout(() => {
      setLoading(true);
      fetchMedication();
    }, 500);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedication, setValue]);

  return (
    <>
      <TabContext value={selectedTab}>
        <TabList onChange={handleChangeTab}>
          <Tab
            value="1"
            label="Nomes comerciais"
            sx={{ textTransform: "none" }}
          />
          <Tab
            value="2"
            label="Princípio ativo"
            sx={{ textTransform: "none" }}
          />
          <Tab
            value="3"
            label="Nomes genéricos"
            sx={{ textTransform: "none" }}
          />
        </TabList>
      </TabContext>

      <Stack rowGap={2}>
        <FormAutoComplete
          required
          loading={loading}
          name="medication"
          freeSolo={customMedication}
          control={control}
          onInputChange={(e: any) => {
            if (e?.target?.value?.length)
              setValue("medication", {
                name: e?.target?.value,
                label: e?.target?.value,
                value: e?.target?.value,
              });
          }}
          label="Selecione o medicamento"
          renderOption={(option: any) => (
            <CustomMedicationOption option={option} />
          )}
          noOptionsText={
            <Button
              sx={{ width: "fit-content", textTransform: "none" }}
              onClick={() => setCustomMedication(true)}
              endIcon={<Publish />}
            >
              Adicionar novo medicamento
            </Button>
          }
          selectOptions={!loading ? medicationOptions : []}
        />

        <Stack direction={{ md: "row" }} gap={2}>
          <FormAutoComplete
            onInputChange={(e: any) => {
              if (e?.target?.value?.length)
                setValue("form", {
                  name: e?.target?.value,
                  label: e?.target?.value,
                  value: e?.target?.value,
                });
            }}
            freeSolo
            name="form"
            control={control}
            label="Forma"
            selectOptions={unit}
          />
          <FormTextInput
            type={verityInputType()}
            name="quantity"
            control={control}
            label="Posologia"
            pattern={/^\d+(\.\d+)?\s*\w*(\s+.*)?$/}
          />
          <FormAutoComplete
            freeSolo
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                const debounce = setTimeout(() => {
                  if (extrairNumero(e?.target?.value) !== null)
                    setValue("frequency", {
                      name: `${extrairNumero(e?.target?.value)} h` || "",
                      label: `${extrairNumero(e?.target?.value)} h` || "",
                      value: extrairNumero(e?.target?.value) || "",
                    });
                  else {
                    setValue("frequency", {
                      name: "",
                      label: "",
                      value: "",
                    });
                  }
                }, 1000);
                return () => clearTimeout(debounce);
              }
            }}
            name="frequency"
            control={control}
            label="Intervalo de uso"
            selectOptions={frequency}
          />
          <FormAutoComplete
            freeSolo
            onInputChange={(e: any) => {
              if (e?.target?.value?.length) {
                const debounce = setTimeout(() => {
                  if (extrairNumero(e?.target?.value) !== null)
                    setValue("time", {
                      name: `${extrairNumero(e?.target?.value)} dias` || "",
                      label: `${extrairNumero(e?.target?.value)} dias` || "",
                      value: extrairNumero(e?.target?.value),
                    });
                  else {
                    setValue("time", {
                      name: "",
                      label: "",
                      value: "",
                    });
                  }
                }, 1000);
                return () => clearTimeout(debounce);
              }
            }}
            name="time"
            control={control}
            label="Tempo de tratamento"
            selectOptions={time}
          />
        </Stack>
      </Stack>
    </>
  );
}
