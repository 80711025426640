import { Close } from "@mui/icons-material";
import { Box, DialogTitle, IconButton, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup, FormDatePicker, FormSelectInput, FormTextInput } from "../../../../components/form";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { PatchPatient } from "../../../../services/fhir/patient/PatchPatient";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { isMobile } from "react-device-detect";

interface IEditDependentModalProps {
  open: boolean;
  onClose: () => void;
  patient: any;
}

interface IFormInput {
  name: string;
  birthDate: Dayjs | null;
  gender: string;
}

const genderOptions = [
  { value: "male", display: "Masculino" },
  { value: "female", display: "Feminino" },
];

export function EditDependentModal({ open, onClose, patient }: IEditDependentModalProps) {
  const { setRefetch } = useSessionContext();
  
  const { control, handleSubmit, reset } = useForm<IFormInput>({
    defaultValues: {
      name: (patient?.name?.find((n: any) => n.use === "official")?.text || patient?.name?.[0]?.text || "").toUpperCase(),
      birthDate: patient?.birthDate ? dayjs(patient.birthDate) : null,
      gender: patient?.gender || "",
    },
  });

  const onSubmit = async (data: IFormInput) => {
    try {
      // Atualizar nome
      if (data.name !== patient?.name?.[0]?.text) {
        // Encontra o índice do nome oficial
        const officialNameIndex = patient?.name?.findIndex((n: any) => n.use === "official") ?? 0;
        
        await PatchPatient({
          patientId: patient.id,
          operation: "replace",
          path: `/name/${officialNameIndex}/text`,
          content: data.name.toUpperCase(),
        });
      }

      // Atualizar data de nascimento
      if (data.birthDate?.format("YYYY-MM-DD") !== patient?.birthDate) {
        await PatchPatient({
          patientId: patient.id,
          operation: "replace",
          path: "/birthDate",
          content: data.birthDate?.format("YYYY-MM-DD"),
        });
      }

      // Atualizar gênero
      if (data.gender !== patient?.gender) {
        await PatchPatient({
          patientId: patient.id,
          operation: "replace",
          path: "/gender",
          content: data.gender,
        });
      }

      toast.success("Dados atualizados com sucesso!");
      setRefetch((prev: boolean) => !prev);
      onClose();
    } catch (error) {
      toast.error("Erro ao atualizar os dados. Tente novamente.");
    }
  };

  return (
    <CommonDialog
      isOpen={open}
      mobileView={isMobile}
      handleClose={() => {
        reset();
        onClose();
      }}
    >
      <DialogTitle align="center">
          Editar Dependente
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <FormTextInput
            control={control}
            name="name"
            label="Nome"
            required
          />

          <FormDatePicker
            control={control}
            name="birthDate"
            label="Data de Nascimento"
            required
            maxDate={dayjs(new Date())}
          />

          <FormSelectInput
            control={control}
            name="gender"
            label="Sexo de nascimento"
            required
            selectOptions={genderOptions}
          />

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <FormButtonGroup 
              onGoBackButtonClick={() => {
                reset();
                onClose();
              }}
              onNextButtonClick={() => {
                handleSubmit(onSubmit)();
              }}
              nextButtonText="Salvar"
              goBackButtonText="Cancelar"
            />
          </Box>
        </Stack>
      </form>
    </CommonDialog>
  );
} 