import { Stack, Typography } from "@mui/material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useFhirData } from "../../../../utils/useFhirData";
import { GenericTimeline } from "../GenericTimeline";
import { ImmunizationHistoryAccordion } from "./ImmunizationHistoryAccordion";

export function ImmunizationHistory({
  reset,
  setEditImmunization,
}: {
  reset: any;
  setEditImmunization: (data: any) => void;
}) {
  const { summaryData } = useMedicalCareContext();

  const immunization = useFhirData.extractPatientSummaryResource(
    summaryData,
    "Immunization"
  );

  const observations = useFhirData
    .extractPatientSummaryResource(summaryData, "Observation")?.[0]
    ?.entry?.filter(
      (observation: any) =>
        observation?.resource?.code?.coding?.[0]?.code === "11369-6"
    );

  const immunizationData = immunization?.[0]?.entry?.map((el: any) => ({
    fullResource: el?.resource,
    id: el?.resource?.id,
    description: el?.resource?.vaccineCode?.coding?.[0]?.display,
    code: el?.resource?.vaccineCode?.coding?.[0]?.code,
    status: el?.resource?.status,
    date: el?.resource?.occurrenceDateTime,
    practitioner: el?.resource?.informationSource?.reference?.display,
    narrative: observations?.find((observation: any) =>
      observation?.resource.partOf?.[0].reference?.includes(el?.resource?.id)
    )?.resource?.valueString,
  }));

  return (
    <Stack spacing={1}>
      {immunizationData?.length > 0 ? (
        <GenericTimeline
          data={immunizationData}
          component={(data: any, index: number) => (
            <ImmunizationHistoryAccordion
              data={data}
              index={index}
              reset={reset}
              setEditImmunization={setEditImmunization}
            />
          )}
        />
      ) : (
        <Typography variant="body2" color="text.secondary" textAlign="center">
          Nenhuma imunização registrada
        </Typography>
      )}
    </Stack>
  );
}
