import { Box, Button, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { NoDataWarning } from "../../../../../components/common";
import { useCustomizationContext } from "../../../../../contexts/CustomizationContext";
import { ExamsFields } from "../ExamsFields";
import { NewItemDialog } from "../NewItemDialog";
import { scrollbarStyle } from "../../scrollbarStyle";

interface ExamesInputProps {
  items: any[];
  selectedTab: number;
  onTabChange: (value: number) => void;
  isMobile?: boolean;
}

export function ExamesInput({
  items,
  selectedTab,
  onTabChange,
  isMobile = false,
}: ExamesInputProps) {
  const { handleDeleteItem, customization, saveCustomization } =
    useCustomizationContext();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Stack
      width={isMobile ? "100%" : "50%"}
      sx={{
        maxHeight: "59vh",
        overflow: "auto",
        ...scrollbarStyle,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {items?.length > 0 && (
          <Tabs value={selectedTab} onChange={(_, value) => onTabChange(value)}>
            {items.map((item, index) => (
              <Tab
                key={index}
                label={`${item?.text} ${
                  item?.text !== "Geral" ? `- ${item?.specialty}` : ""
                }`}
              />
            ))}
          </Tabs>
        )}
      </Box>

      {items?.[selectedTab]?.itens?.length ||
      items?.[selectedTab]?.specialty === "Geral" ? (
        <>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {items[selectedTab]?.itens?.map((item: any, index: number) => (
              <ExamsFields
                key={index}
                item={item}
                type={items[selectedTab].specialty}
                handleDeleteItem={() =>
                  handleDeleteItem(items[selectedTab].specialty, index)
                }
                disabled={items[selectedTab].itens?.length === 1}
              />
            ))}
          </Grid>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              onClick={() => setOpenDialog(true)}
            >
              {`Personalizar exame ${
                selectedTab === 0 ? "geral" : "específico"
              }`}
            </Button>

            {customization && (
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={async () => {
                  await saveCustomization();
                  toast.success("Personalização realizada com sucesso!");
                }}
              >
                Salvar personalização
              </Button>
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <NoDataWarning message="Nenhum item configurado para avaliação" />
          <Button variant="outlined" onClick={() => setOpenDialog(true)}>
            Configurar agora
          </Button>
        </Stack>
      )}

      {openDialog && (
        <NewItemDialog
          isOpen={openDialog}
          handleClose={() => setOpenDialog(false)}
          specialty={items?.[selectedTab]?.specialty}
        />
      )}
    </Stack>
  );
}
