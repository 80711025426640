import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
interface IProps {
  handleClose: any;
  isOpen: boolean;
  data: any;
}

export function ObservationEncounterVisualizer({
  data,
  handleClose,
  isOpen,
}: IProps) {
  const translateCode: any = {
    wk: "semanas",
    mo: "meses",
  };
  const verifyValue = (el: any) => {
    const resource = el?.resource;

    // Caso especial para pressão arterial
    if (resource?.code?.coding?.[0]?.code === "55284-4") {
      const sistolic = resource?.component?.[0]?.valueQuantity?.value || "-";
      const diastolic = resource?.component?.[1]?.valueQuantity?.value || "-";
      return `${sistolic}/${diastolic} mmHg`;
    }

    // Verifica cada tipo de valor possível
    if (resource?.hasOwnProperty("valueString")) {
      return resource.valueString;
    }

    if (resource?.hasOwnProperty("valueInteger")) {
      return resource.valueInteger.toString();
    }

    if (resource?.hasOwnProperty("valueCodeableConcept")) {
      return (
        resource.valueCodeableConcept?.coding?.[0]?.display ||
        resource.valueCodeableConcept?.text
      );
    }

    if (resource?.hasOwnProperty("valueQuantity")) {
      const value = resource.valueQuantity?.value;
      const code = resource.valueQuantity?.code;

      if (value === undefined) {
        return code ? translateCode[code] || code : "-";
      }
      if (code === undefined) {
        return value.toString();
      }
      return `${value} ${translateCode[code] || code}`;
    }

    if (resource?.hasOwnProperty("valueDateTime")) {
      return resource.valueDateTime
        ? dayjs(resource.valueDateTime).format("DD/MM/YYYY")
        : "-";
    }

    return "-";
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "1200px",
        },
      }}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      open={isOpen}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 5, top: 5 }}
      >
        <Close />
      </IconButton>
      <DialogTitle textAlign="left">Observação</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {data?.map((observation: any) => (
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight={600}>
                {observation?.resource?.code?.text ||
                  observation?.resource?.code?.coding?.[0]?.display ||
                  ""}
              </Typography>
              <Typography variant="body1" fontWeight={400}>
                {verifyValue(observation)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
