import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Stack,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

import dayjs from "dayjs";
import { GenericTable } from "../../../../../components/GenericTable/GenericTable";
import { useDatetime } from "../../../../../utils/useDatetime";

import { useDict } from "../../../../../utils/useDict";
import { useSessionContext } from "../../../../../contexts/SessionContext";
import { ImmunizationsActions } from "./ImmunizationActions";

export function Immunizations({
  data,
  title,
  openVisualizer,
  verifyVisualizer,
}: any) {
  const { access } = useSessionContext();
  const { formatToDDMMYYYY } = useDatetime;

  const columns = [
    { column: "Vacina", rowName: "vaccine" },
    { column: "Data", rowName: "date" },
    // { column: "Status", rowName: "status" },
    { column: "Profissional", rowName: "professional" },
  ];

  const rowData = data?.[0]?.entry?.map((el: any) => ({
    encounterId: el?.resource?.encounter?.reference?.split("/")?.[1],
    vaccine: el?.resource?.vaccineCode?.coding?.[0]?.display || "-",
    date: formatToDDMMYYYY(dayjs(el?.resource?.occurrenceDateTime)),
    status: el?.resource?.status || "-",
    professional: el?.resource?.informationSource?.reference?.display || "-",
    fullResource: el?.resource, // Mantido para uso em outras funcionalidades
  }));

  return (
    <Accordion sx={{ width: "100%" }} elevation={5}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {title}
          <Avatar
            sx={{
              width: 26,
              height: 26,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "primary100.main",
              color: "primary1000.main",
            }}
          >
            {rowData?.length || "0"}
          </Avatar>
        </Stack>
      </AccordionSummary>

      <AccordionDetails>
        <GenericTable
          tableColumns={columns}
          tableRows={rowData}
          actions={
            access?.type === "patient"
              ? undefined
              : (rowData: any) => (
                  <ImmunizationsActions
                    row={rowData}
                    openVisualizer={openVisualizer}
                    verifyVisualizer={verifyVisualizer}
                  />
                )
          }
        />
      </AccordionDetails>
    </Accordion>
  );
}
