/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack } from "@mui/material";

import { useState } from "react";
import { useQuestionnaireContext } from "../../../../../contexts/QuestionnaireContext";
import { GenericTimeline } from "../../GenericTimeline";
import { AnamneseHistoryAccordion } from "./AnamneseHistoryAccordion";
import { scrollbarStyle } from "../../scrollbarStyle";

export function AnamneseHistory({ selectedField, isMobile }: any) {
  const [filter, setFilter] = useState<any>(2);
  const { response, historyValues } = useQuestionnaireContext();
  const responseEntries = historyValues?.map((el: any) => el?.resource);
  const anamneseResponses = responseEntries?.map((el: any) => {
    const awnserDate = el?.authored;
    const filterItens = el?.item?.filter((e: any) => e?.text === "Anamnese");
    const displayReferences = el?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
    };
    return { awnserDate, filterItens, displayInfo };
  });

  const awnsers = anamneseResponses?.map((el: any) => {
    const awnserDate = el.awnserDate;
    const displayInfo = el.displayInfo;
    const filterResponse = el?.filterItens?.[0]?.item;
    return { awnserDate, filterResponse, displayInfo };
  });
  // const filterByItem = filterGeral?.map((el: any) => {
  //   console.log("el", el);
  //   const auxAwnser = el?.filter((e: any) => e.linkId === selectedField);

  //   return auxAwnser;
  // });

  // filter === 1 ? filterByItem : filterGeral;

  return (
    <Stack
      sx={{
        height: "100%",
        overflow: "auto",
        ...scrollbarStyle,
      }}
    >
      {/* <Stack
        justifyContent="right"
        direction="row"
        width="100%"
        alignItems="center"
        spacing={1}
      >
        <FormLabel component="legend">Exibir:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          aria-label="position"
          onChange={(e) => setFilter(Number(e?.target?.value))}
          defaultValue={1}
          value={filter}
        >
          <FormControlLabel
            key={1}
            value={1}
            control={<Radio />}
            label="Item"
          />
          <FormControlLabel
            key={2}
            value={2}
            control={<Radio />}
            label="Geral"
          />
        </RadioGroup>
      </Stack> */}
      <GenericTimeline
        data={awnsers}
        component={(data: any, index: number) => (
          <AnamneseHistoryAccordion
            filter={filter}
            data={data}
            index={index}
            isMobile={isMobile}
          />
        )}
      />
    </Stack>
  );
}
